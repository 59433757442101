import EdiText from 'react-editext';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  FixedTemplateBlockName,
  selectHeaderLinks,
  useGetTemplateQuery,
  useUpdateTextMutation,
} from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import { ISectionProps } from 'types/props';

import { Button } from 'components/Button';
import s from './Connect.module.scss';

interface ICTAProps extends ISectionProps {}

export const Connect: React.FC<ICTAProps> = (props) => {
  const { section, children, template } = props;

  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });

  const isEditMode = useSelector(isEditModeSelector);
  const [updateText] = useUpdateTextMutation();
  const CTASectionContext = section?.child?.context;
  const links = useSelector(selectHeaderLinks(domain));
  const contactsSection = links.find((link) => link.name === FixedTemplateBlockName.CONTACTS);

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <section className={s.connect} id={section.id}>
      <div className='container'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          type={'text'}
          value={CTASectionContext.ctaTitle}
          onSave={(value) => handleSave(value, 'ctaTitle')}
          className={s.connect__title}
        />
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          type={'text'}
          value={CTASectionContext.ctaDescription}
          onSave={(value) => handleSave(value, 'ctaDescription')}
          className={s.connect__descr}
        />
        <Button
          className={classNames(s.connect__btn, 'btn', `btn--${template}`)}
          containerClassName={'m-auto'}
          id={section.id}
          btnValue={CTASectionContext.ctaCTA}
          btnLink={CTASectionContext.ctaLink}
          btnPropName={'ctaCTA'}
          btnLinkPropName={'ctaLink'}
          to={contactsSection?.id ?? ''}
        />
      </div>
      {children}
    </section>
  );
};
