import team1 from '../../images/team-1.jpg';
import team2 from '../../images/team-2.png';
import team3 from '../../images/team-3.png';
import team4 from '../../images/team-4.png';
import team5 from '../../images/team-5.png';
import team6 from '../../images/team-6.png';
import { Team } from './components/Team';
import type { ITeamProps } from './components/Team';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { useSelector } from 'react-redux';
import { isEditModeSelector } from 'slices/globalSlice';
import EdiText from 'react-editext';
import { ISectionProps } from 'types/props';
import s from './Teams.module.scss';

const BACKGROUNDS: Record<string, string> = {
  team1,
  team2,
  team3,
  team4,
  team5,
  team6,
};

interface ITeamsProps extends ISectionProps {}

export const Teams: React.FC<ITeamsProps> = (props) => {
  const { section, children } = props;
  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const teamsSectionContext = section.child.context;
  const teamsSection: ITeamProps[] = section.children.map((elem, i) => ({
    id: elem.id,
    teamImg: elem.child.context.contentImageUrl || BACKGROUNDS[`team${i + 1}`],
    itemName: elem.child.context.itemName,
    itemRole: elem.child.context.itemRole,
  }));
  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <section className={s.teams} id={section.id}>
      <div className='container'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.teams__title}
          type={'text'}
          value={teamsSectionContext.teamTitle || 'Meet our team members'}
          onSave={(value) => handleSave(value, 'teamTitle')}
        />
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.teams__descr}
          type={'text'}
          value={
            teamsSectionContext.teamDescription ||
            'Our Strength Lies in Our People: Dedicated Professionals Crafting Your Success'
          }
          onSave={(value) => handleSave(value, 'teamDescription')}
        />
        <div className={s.teams__wrap}>
          {teamsSection.map((team) => (
            <Team key={team.id} {...team} />
          ))}
        </div>
      </div>
      {children}
    </section>
  );
};
