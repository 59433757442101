import { ReactComponent as GoogleIcon } from '../../images/Google.svg';
import { ReactComponent as FacebookIcon } from '../../images/Facebook.svg';
import { ReactComponent as YouTubeIcon } from '../../images/YouTube.svg';
import { ReactComponent as PinterestIcon } from '../../images/Pinterest.svg';
import { ReactComponent as TwitchIcon } from '../../images/Twitch.svg';
import { ReactComponent as WebflowIcon } from '../../images/Webflow.svg';

import './partners.scss';
import { ISectionProps } from 'types/props';
import EdiText from 'react-editext';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { useSelector } from 'react-redux';
import { isEditModeSelector } from 'slices/globalSlice';
import { ImageUploader } from 'components/ImageUploader';

interface IPartnersProps extends ISectionProps {}

const ICONS: Record<string, React.FunctionComponent<React.SVGProps<SVGSVGElement>>> = {
  Icon0: GoogleIcon,
  Icon1: FacebookIcon,
  Icon2: YouTubeIcon,
  Icon3: PinterestIcon,
  Icon4: TwitchIcon,
  Icon5: WebflowIcon,
};

export const Partners: React.FC<IPartnersProps> = (props) => {
  const { section, children } = props;
  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');
  const partnersSectionContext = section?.child?.context;
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const handleSave = (context: Record<string, string>, id: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context,
      },
    });
  };

  const customers = section.children.map((elem, i) => ({
    id: elem.id,
    itemTitle: elem.child.context.customersTitle,
    itemDescription: elem.child.context.customersDescription,
    contentImageUrl: elem.child.context.contentImageUrl,
    ReactIcon: ICONS[`Icon${i}`],
  }));

  return (
    <section className='partners' id={section.id}>
      <div className='container'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          type={'text'}
          className='partners__title centerEditext'
          value={
            partnersSectionContext.customersTitle || 'Trusted by 1000+ companies around the world'
          }
          onSave={(value) => handleSave({ customersTitle: value }, section.id)}
        />
        <div className='partners__overflow'>
          <div className='partners__wrap'>
            {customers.map(({ contentImageUrl, id, ReactIcon }) => (
              <div className='partners__item' key={id}>
                {contentImageUrl || isEditMode ? (
                  <ImageUploader src={contentImageUrl} alt='' nodeId={id} />
                ) : (
                  <span>{ReactIcon && <ReactIcon />}</span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {children}
    </section>
  );
};
