import { Advantage } from './components/AdvService';
import type { IAdvantageProps } from './components/AdvService';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import s from './Advantages.module.scss';
import { isEditModeSelector } from 'slices/globalSlice';
import { useSelector } from 'react-redux';
import EdiText from 'react-editext';

import { ISectionProps } from 'types/props';

interface IAdvantagesProps extends ISectionProps {}

export const Advantages: React.FC<IAdvantagesProps> = (props) => {
  const { section, children } = props;
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const isEditMode = useSelector(isEditModeSelector);
  const [updateText] = useUpdateTextMutation();

  const advantageSectionContext = section?.child?.context;

  const advantageSections: IAdvantageProps[] = section.children.map((elem) => ({
    id: elem.id,
    itemTitle: elem.child.context.itemTitle,
    itemDescription: elem.child.context.itemDescription,
    itemCTA: elem.child.context.itemCTA,
    itemCTALink: elem.child.context.itemCTALink,
  }));

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <section className={s.advantages} id={section.id}>
      <div className='container'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.advantages__title}
          type={'text'}
          value={advantageSectionContext.advantagesTitle}
          onSave={(value) => handleSave(value, 'advantagesTitle')}
        />
        <div className={s.advantages__wrap}>
          {advantageSections.map((advantage) => (
            <Advantage key={advantage.id} {...advantage} />
          ))}
        </div>
      </div>
      {children}
    </section>
  );
};
