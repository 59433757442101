import { ISectionProps } from 'types/props';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import EdiText from 'react-editext';
import { isEditModeSelector } from 'slices/globalSlice';
import { useSelector } from 'react-redux';
import s from './Form.module.scss';
import classNames from 'classnames';

interface IFormProps extends ISectionProps {}

export const Form: React.FC<IFormProps> = (props) => {
  const { section, children } = props;
  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const formSectionContext = section?.child?.context;

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <section className={classNames(s.form, 'template-form')} id={section.id}>
      <div className='container'>
        <div className={s.form__wrap}>
          <EdiText
            editButtonProps={{ hidden: !isEditMode }}
            type={'text'}
            value={formSectionContext.formDescription}
            onSave={(value) => handleSave(value, 'formDescription')}
            className={s.form__title}
          />
          <div className={s.form__form}>
            <form action=''>
              <EdiText
                editButtonProps={{ hidden: !isEditMode }}
                type={'text'}
                value={formSectionContext.formTitle}
                onSave={(value) => handleSave(value, 'formTitle')}
                className={s.form__heading}
              />
              <input type='text' placeholder='Name' />
              <input type='email' placeholder='Email' />
              <textarea placeholder={formSectionContext.services} />

              <label className='checkbox' htmlFor='check'>
                <input type='checkbox' id='check' />
                <span>I would like to receive a monthly newsletter</span>
              </label>
              <input type='submit' value={formSectionContext.formCTA} />
            </form>
          </div>
        </div>
      </div>
      <div className={s.form__bg} />
      {children}
    </section>
  );
};
