import classnames from 'classnames';
import s from './Loading.module.scss';

interface ILoadingProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Loading: React.FC<ILoadingProps> = (props) => {
  return (
    <div className={s.loaderContainer} {...props}>
      <div className={classnames(s.loader)} />
    </div>
  );
};
