import EdiText from 'react-editext';
import { useSelector } from 'react-redux';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import s from './Contacts.module.scss';

import { ISectionProps } from 'types/props';

interface IContactsProps extends ISectionProps {}

export const Contacts: React.FC<IContactsProps> = (props) => {
  const { section, children } = props;

  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();
  const contactsSectionContext = section?.child?.context;

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <section className={s.contacts} id={section.id}>
      <div className='container'>
        <div className={s.contacts__wrap}>
          <EdiText
            editButtonProps={{ hidden: !isEditMode }}
            type={'text'}
            value={contactsSectionContext.contactsTitle}
            onSave={(value) => handleSave(value, 'contactsTitle')}
            className={s.contacts__title}
          />
          <div className={s.contacts__decsr}>
            <EdiText
              editButtonProps={{ hidden: !isEditMode }}
              type={'text'}
              value={contactsSectionContext.contacts}
              onSave={(value) => handleSave(value, 'contacts')}
            />
          </div>
        </div>
      </div>
      {children}
    </section>
  );
};
