import { useRef, useState } from 'react';
import EdiText from 'react-editext';
import { useSelector } from 'react-redux';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';

interface IFaqsCardProps {
  faqsCardId: string;
  question: string;
  answer: string;
}

export const FaqsCard: React.FC<IFaqsCardProps> = (props) => {
  const { question, answer, faqsCardId } = props;

  const answerElRef = useRef<HTMLDivElement | null>(null);
  const [state, setState] = useState(false);
  const [answerH, setAnswerH] = useState('0px');

  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');

  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const handleSave = (context: Record<string, string>, id: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context,
      },
    });
  };

  const handleOpenAnswer = () => {
    if (isEditMode && state) return;

    const answerElH = (answerElRef.current?.childNodes?.[0] as HTMLElement)?.offsetHeight;
    setState(!state);
    setAnswerH(`${answerElH + 20}px`);
  };

  return (
    <div
      className='space-y-3 mt-5 overflow-hidden border-b'
      onClick={handleOpenAnswer}
      role='presentation'
    >
      <h4 className='cursor-pointer pb-5 flex items-center justify-between text-lg text-gray-700 font-medium'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          type={'text'}
          value={question}
          onSave={(value) => handleSave({ itemTitle: value }, faqsCardId)}
        />
        {state ? (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-5 w-5 text-gray-500 ml-2'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M20 12H4' />
          </svg>
        ) : (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-5 w-5 text-gray-500 ml-2'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 4v16m8-8H4' />
          </svg>
        )}
      </h4>
      <div
        ref={answerElRef}
        className='duration-300'
        style={state ? { height: answerH } : { height: '0px' }}
      >
        <div>
          <EdiText
            editButtonProps={{ hidden: !isEditMode }}
            type={'text'}
            className='text-gray-500'
            value={answer}
            onSave={(value) => handleSave({ itemAnswer: value }, faqsCardId)}
          />
        </div>
      </div>
    </div>
  );
};
