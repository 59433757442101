import EdiText from 'react-editext';

import { ReactComponent as HeadSection } from '../../images/head-section.svg';
import { isEditModeSelector } from 'slices/globalSlice';
import { useSelector } from 'react-redux';
import {
  FixedTemplateBlockName,
  selectHeaderLinks,
  useGetTemplateQuery,
  useUpdateTextMutation,
} from 'services/templateService';
import s from './Head.module.scss';
import { ISectionProps } from 'types/props';
import classNames from 'classnames';
import { Button } from 'components/Button';

interface IHeadProps extends ISectionProps {}

export const Head: React.FC<IHeadProps> = (props) => {
  const { section, children, template } = props;
  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const aboutUsSectionContext = section?.child?.context;
  const links = useSelector(selectHeaderLinks(domain));
  const contactsSection = links.find((link) => link.name === FixedTemplateBlockName.CONTACTS);

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <section className={s.head} id={section.id}>
      <div className='container'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.head__title}
          type={'text'}
          value={aboutUsSectionContext.businessName}
          onSave={(value) => handleSave(value, 'businessName')}
        />
        <div className={s.head__wrap}>
          <div className={s.head__info}>
            <h2 className={s['head__sub-title']}>
              <span>
                <EdiText
                  editButtonProps={{ hidden: !isEditMode }}
                  type={'text'}
                  value={aboutUsSectionContext.aboutTitle}
                  onSave={(value) => handleSave(value, 'aboutTitle')}
                />
              </span>
            </h2>
            <div className={s.head__descr}>
              <EdiText
                editButtonProps={{ hidden: !isEditMode }}
                type={'text'}
                value={aboutUsSectionContext.aboutDescription}
                onSave={(value) => handleSave(value, 'aboutDescription')}
              />
            </div>
            <Button
              className={classNames('btn', `btn--${template}`)}
              id={section.id}
              btnValue={aboutUsSectionContext.aboutCTA}
              btnLink={aboutUsSectionContext.aboutLink}
              btnPropName={'aboutCTA'}
              btnLinkPropName={'aboutLink'}
              to={contactsSection?.id ?? ''}
            >
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M13.1667 6L19 12M19 12L13.1667 18M19 12L5 12'
                  stroke='white'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </Button>
          </div>
          <div className={s.head__img}>
            <HeadSection />
          </div>
        </div>
      </div>
      {children}
    </section>
  );
};
