import EdiText from 'react-editext';
import { useSelector } from 'react-redux';
import {
  FixedTemplateBlockName,
  selectHeaderLinks,
  useGetTemplateQuery,
  useUpdateTextMutation,
} from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import { Button } from 'components/Button';
import { ISectionProps } from 'types/props';
import s from './Head.module.scss';
import classNames from 'classnames';

interface IHeadProps extends ISectionProps {}

export const Head: React.FC<IHeadProps> = (props) => {
  const { section, children } = props;
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const isEditMode = useSelector(isEditModeSelector);
  const links = useSelector(selectHeaderLinks(domain));
  const contactsSection = links.find((link) => link.name === FixedTemplateBlockName.CONTACTS);

  const aboutUsSectionContext = section?.child?.context;

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <section className={s.head} id={section.id}>
      <div className='container'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.head__title}
          type={'text'}
          value={aboutUsSectionContext.aboutTitle || aboutUsSectionContext.businessName}
          onSave={(value) => handleSave(value, 'aboutTitle')}
        />
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.head__description}
          type={'text'}
          value={aboutUsSectionContext.aboutDescription}
          onSave={(value) => handleSave(value, 'aboutDescription')}
        />
        <Button
          className={classNames(s.discuss__btn, 'btn', `btn--light`)}
          id={section.id}
          btnValue={aboutUsSectionContext.aboutCTA}
          btnLink={aboutUsSectionContext.aboutLink}
          btnPropName={'aboutCTA'}
          btnLinkPropName={'aboutLink'}
          to={contactsSection?.id ?? ''}
        >
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M18 13V19C18 19.5304 17.7893 20.0391 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V8C3 7.46957 3.21071 6.96086 3.58579 6.58579C3.96086 6.21071 4.46957 6 5 6H11'
              stroke='#F8F8F8'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M15 3H21V9'
              stroke='#F8F8F8'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M10 14L21 3'
              stroke='#F8F8F8'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </Button>
      </div>
      {children}
    </section>
  );
};
