import EdiText from 'react-editext';
import { useSelector } from 'react-redux';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import { ISectionProps } from 'types/props';
import { getIconOrderCounter } from 'utils/getIconOrderCounter';
import Reviewer1 from 'assets/blog/reviewer1.png';
import Reviewer2 from 'assets/blog/reviewer2.png';
import Reviewer3 from 'assets/blog/reviewer3.png';
import Post1 from 'assets/blog/post1.png';
import Post2 from 'assets/blog/post2.png';
import Post3 from 'assets/blog/post3.png';
import { ImageUploader } from 'components/ImageUploader';
import s from './Blog.module.scss';
import { locationService } from 'services/locationService';

const PHOTOS = [Post1, Post2, Post3];

const REVIEWERS = [Reviewer1, Reviewer2, Reviewer3];

interface IBlogProps extends ISectionProps {}

export const Blog: React.FC<IBlogProps> = (props) => {
  const { section, children } = props;

  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');
  const blogSectionContext = section?.child?.context;

  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();
  const getBlogPhotoOrder = getIconOrderCounter(3);
  const getAvatarPhotoOrder = getIconOrderCounter(3);

  const handleSave = (context: Record<string, string>, id: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context,
      },
    });
  };

  const posts = section.children.map((elem) => ({
    id: elem.id,
    itemTitle: elem.child.context.itemTitle,
    itemDescription: elem.child.context.itemDescription,
    itemAuthor: elem.child.context.itemAuthor,
    itemDate: elem.child.context.itemDate,
    itemText: elem.child.context.itemText,
    itemPhoto: elem.child.context.blogPhotoPreview || PHOTOS[getBlogPhotoOrder() - 1],
    itemAvatar: elem.child.context.blogAvatarPhoto || REVIEWERS[getAvatarPhotoOrder() - 1],
  }));

  return (
    <section className='mx-auto' id={section.id}>
      <div className={'container'}>
        <div className='text-center'>
          <EdiText
            editButtonProps={{ hidden: !isEditMode }}
            type={'text'}
            className='text-3xl text-gray-800 font-semibold centerEditext'
            value={blogSectionContext.blogTitle}
            onSave={(value) => handleSave({ blogTitle: value }, section.id)}
          />
          <EdiText
            editButtonProps={{ hidden: !isEditMode }}
            type={'text'}
            className='mt-3 text-gray-500 centerEditext'
            value={blogSectionContext.blogDescription}
            onSave={(value) => handleSave({ blogDescription: value }, section.id)}
          />
        </div>
        <div className='mt-12 grid gap-2 sm:grid-cols-2 lg:grid-cols-3'>
          {posts.map((items, key) => {
            const handleGoToBlogPage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              if (
                (e.target as HTMLDivElement).hasAttribute('editext') ||
                (e.target as HTMLDivElement).hasAttribute('data-imageupload')
              ) {
                return;
              }

              locationService.goToBlogPage(domain, items.id, {
                itemPhoto: items.itemPhoto,
                itemAvatar: items.itemAvatar,
              });
            };

            return (
              <article
                className='max-w-md mx-auto mt-4 shadow-lg border rounded-md duration-300 hover:shadow-sm'
                key={key}
              >
                <div role='presentation' onClick={handleGoToBlogPage} className='cursor-pointer'>
                  <ImageUploader
                    src={items.itemPhoto}
                    alt={items.itemTitle}
                    className='w-full h-48 rounded-t-md'
                    nodeId={items.id}
                    contextImageParam={'blogPhotoPreview'}
                    uploaderClassName={s.Blog__imageUploader}
                  />
                  <div className='flex items-center mt-2 pt-3 ml-4 mr-2'>
                    <div className='flex-none w-10 h-10 rounded-full'>
                      <ImageUploader
                        src={items.itemAvatar}
                        alt={items.itemAuthor}
                        className='w-full h-full rounded-full'
                        nodeId={items.id}
                        contextImageParam={'blogAvatarPhoto'}
                      />
                    </div>
                    <div className='ml-3'>
                      <EdiText
                        editButtonProps={{ hidden: !isEditMode }}
                        type={'text'}
                        className='block text-gray-900'
                        value={items.itemAuthor}
                        onSave={(value) => handleSave({ itemAuthor: value }, items.id)}
                      />
                      <EdiText
                        editButtonProps={{ hidden: !isEditMode }}
                        type={'text'}
                        className='block text-gray-400 text-sm'
                        value={items.itemDate}
                        onSave={(value) => handleSave({ itemDate: value }, items.id)}
                      />
                    </div>
                  </div>
                  <div className='pt-3 ml-4 mr-2 mb-3'>
                    <EdiText
                      editButtonProps={{ hidden: !isEditMode }}
                      type={'text'}
                      className='text-xl text-gray-900'
                      value={items.itemTitle}
                      onSave={(value) => handleSave({ itemTitle: value }, items.id)}
                    />
                    <EdiText
                      editButtonProps={{ hidden: !isEditMode }}
                      type={'text'}
                      className='text-gray-400 text-sm mt-1'
                      value={items.itemDescription}
                      onSave={(value) => handleSave({ itemDescription: value }, items.id)}
                    />
                  </div>
                </div>
              </article>
            );
          })}
        </div>
      </div>
      {children}
    </section>
  );
};
