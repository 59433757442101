import EdiText from 'react-editext';
import 'swiper/css';
import 'swiper/css/navigation';

import './achievement.scss';
import { isEditModeSelector } from 'slices/globalSlice';
import { useSelector } from 'react-redux';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';

export interface IAchievementProps {
  id: string;
  itemMetric: string;
  itemValue: string;
}

export const Achievement: React.FC<IAchievementProps> = (props) => {
  const { id, itemMetric, itemValue } = props;
  const isEditMode = useSelector(isEditModeSelector);

  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <li className='achievement'>
      <EdiText
        editButtonProps={{ hidden: !isEditMode }}
        type={'text'}
        value={itemValue}
        onSave={(value) => handleSave(value, 'itemValue')}
        className='achievement__value'
      />
      <EdiText
        editButtonProps={{ hidden: !isEditMode }}
        type={'text'}
        value={itemMetric}
        onSave={(value) => handleSave(value, 'itemMetric')}
      />
    </li>
  );
};
