import EdiText from 'react-editext';

import s from './Testimonial.module.scss';
import { isEditModeSelector } from 'slices/globalSlice';
import { useSelector } from 'react-redux';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { ImageUploader } from 'components/ImageUploader';

export interface ITestimonialProps {
  id: string;
  reviewText: string;
  ratingImage: string;
  userImage: string;
  reviewerName: string;
  // companyName: string;
}

export const Testimonial: React.FC<ITestimonialProps> = (props) => {
  const { reviewText, ratingImage, userImage, reviewerName, id } = props;
  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <div className={s.Testimonial__slide}>
      <div className={s.Testimonial__info}>
        <span className={s.Testimonial__quote}>“</span>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.Testimonial__text}
          type={'text'}
          value={reviewText}
          onSave={(value) => handleSave(value, 'reviewText')}
        />
        <img className={s.Testimonial__rating} src={ratingImage} alt='' />
        <div className={s.Testimonial__user}>
          <ImageUploader src={userImage} alt='User avatar' nodeId={id} />
          <div className={s.Testimonial__name}>
            <EdiText
              editButtonProps={{ hidden: !isEditMode }}
              className={s['Testimonial__name-person']}
              type={'text'}
              value={reviewerName}
              onSave={(value) => handleSave(value, 'reviewerName')}
            />
            {/* <EdiText
              editButtonProps={{ hidden: !isEditMode }}
              type={'text'}
              value={companyName}
              onSave={handleSave}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
