import { ImageUploader } from 'components/ImageUploader';
import EdiText from 'react-editext';

import { useSelector } from 'react-redux';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import s from './Testimonial.module.scss';

export interface ITestimonialProps {
  id: string;
  reviewText: string;
  reviewerName: string;
  userImage: string;
}

export const Testimonial: React.FC<ITestimonialProps> = (props) => {
  const { reviewerName, reviewText, userImage, id } = props;
  const isEditMode = useSelector(isEditModeSelector);

  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <div className={s.Testimonial}>
      <ImageUploader
        src={userImage}
        alt='User avatar'
        className={s.Testimonial__photo}
        nodeId={id}
      />
      <div className={s.Testimonial__content}>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.Testimonial__top}
          type={'text'}
          value={reviewText}
          onSave={(value) => handleSave(value, 'reviewText')}
        />
        <div className={s.Testimonial__foot}>
          <EdiText
            editButtonProps={{ hidden: !isEditMode }}
            className={s.Testimonial__name}
            type={'text'}
            value={reviewerName}
            onSave={(value) => handleSave(value, 'reviewerName')}
          />
          {/* <EdiText
            editButtonProps={{ hidden: !isEditMode }}
            type={'text'}
            value={companyName}
            onSave={handleSave}
          /> */}
        </div>
      </div>
    </div>
  );
};
