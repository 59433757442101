import {
  ABOUT_US,
  FOOTER,
  HEADER,
  SERVICES,
  TESTIMONIALS,
  ADVANTAGES,
  CONTACTS,
  CTA,
  ACHIEVEMENTS,
  TEAM,
  FORM,
  ANIMATION,
  CROSS_ANIMATION,
  PARTNERS,
  PRICING,
  FAQS,
  PORTFOLIO,
  BLOG,
  GALLERY,
  MOBILE_IOS,
  MOBILE_ANDROID,
} from 'constants/index';
import { FixedTemplateBlockName, TTemplateName } from 'services/templateService';
import { ISectionProps } from 'types/props';

export const getComponent = (
  name: FixedTemplateBlockName,
  type: TTemplateName
): React.FC<ISectionProps> | null => {
  switch (name) {
    case FixedTemplateBlockName.HEADER:
      return HEADER[type];
    case FixedTemplateBlockName.FOOTER:
      return FOOTER[type];
    case FixedTemplateBlockName.FORM:
      return FORM[type];
    case FixedTemplateBlockName.ABOUT:
      return ABOUT_US[type];
    case FixedTemplateBlockName.SERVICES:
      return SERVICES[type];
    case FixedTemplateBlockName.TESTIMONIALS:
      return TESTIMONIALS[type];
    case FixedTemplateBlockName.ADVANTAGES:
      return ADVANTAGES[type];
    case FixedTemplateBlockName.CONTACTS:
      return CONTACTS[type];
    case FixedTemplateBlockName.CTA:
      return CTA[type];
    case FixedTemplateBlockName.ACHIEVEMENTS:
      return ACHIEVEMENTS[type];
    case FixedTemplateBlockName.TEAM:
      return TEAM[type];
    case FixedTemplateBlockName.ANIMATION:
      return ANIMATION[type];
    case FixedTemplateBlockName.CROSSANIMATION:
      return CROSS_ANIMATION[type];
    case FixedTemplateBlockName.CUSTOMERS:
      return PARTNERS[type];
    case FixedTemplateBlockName.PRICING:
      return PRICING[type];
    case FixedTemplateBlockName.FAQS:
      return FAQS[type];
    case FixedTemplateBlockName.PORTFOLIO:
      return PORTFOLIO[type];
    case FixedTemplateBlockName.BLOG:
      return BLOG[type];
    case FixedTemplateBlockName.GALLERY:
      return GALLERY[type];
    case FixedTemplateBlockName.MOBILE_IOS:
      return MOBILE_IOS[type];
    case FixedTemplateBlockName.MOBILE_ANDROID:
      return MOBILE_ANDROID[type];
    default:
      return null;
  }
};
