import { SVGProps } from 'react';

import { ReactComponent as AdvantagesIcon1 } from '../../images/adv-1.svg';
import { ReactComponent as AdvantagesIcon2 } from '../../images/adv-2.svg';
import { ReactComponent as AdvantagesIcon3 } from '../../images/adv-3.svg';
import { Advantage, type IAdvantageProps } from './components/Advantage';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { useSelector } from 'react-redux';
import { isEditModeSelector } from 'slices/globalSlice';
import EdiText from 'react-editext';
import { ISectionProps } from 'types/props';
import s from './Advantages.module.scss';

const ADVANTAGE_ICONS: Record<string, React.FC<SVGProps<SVGSVGElement>>> = {
  AdvantagesIcon1,
  AdvantagesIcon2,
  AdvantagesIcon3,
};

interface IAdvantagesProps extends ISectionProps {}

export const Advantages: React.FC<IAdvantagesProps> = (props) => {
  const { section, children } = props;

  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const isEditMode = useSelector(isEditModeSelector);
  const [updateText] = useUpdateTextMutation();

  const advantageSectionContext = section?.child?.context;

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  const advServicesSection: IAdvantageProps[] = section.children.map((elem, i) => ({
    id: elem.id,
    itemTitle: elem.child.context.itemTitle,
    itemDescription: elem.child.context.itemDescription,
    AdvantageImage: ADVANTAGE_ICONS[`AdvantagesIcon${i + 1}`],
    contentImageUrl: elem.child.context.contentImageUrl,
  }));

  return (
    <section className={s.advantages} id={section.id}>
      <div className='container'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={'title centerEditext'}
          type={'text'}
          value={advantageSectionContext.advantagesTitle || 'Our Advantages'}
          onSave={(value) => handleSave(value, 'advantagesTitle')}
        />
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={'description centerEditext'}
          type={'text'}
          value={
            advantageSectionContext.advantagesDescription || 'Here’s why people love our company'
          }
          onSave={(value) => handleSave(value, 'advantagesDescription')}
        />
        <div className={s.advantages__wrap}>
          {advServicesSection.map((advantage) => (
            <Advantage key={advantage.id} {...advantage} />
          ))}
        </div>
      </div>
      {children}
    </section>
  );
};
