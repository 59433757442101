export enum FixedTemplateBlockName {
  ABOUT = 'about',
  TESTIMONIALS = 'testimonials',
  SERVICES = 'services',
  CONTACTS = 'contacts',
  ADVANTAGES = 'advantages',
  FORM = 'form',
  TEAM = 'team',
  CTA = 'cta',
  HEADER = 'header',
  FOOTER = 'footer',
  PRICING = 'pricing',
  FAQS = 'faq',
  PORTFOLIO = 'portfolio',
  BLOG = 'blog',
  GALLERY = 'gallery',
  ACHIEVEMENTS = 'achievements',
  CUSTOMERS = 'customers',
  ANIMATION = 'animation',
  CROSSANIMATION = 'crossanimation',
  MOBILE_IOS = 'iosApp',
  MOBILE_ANDROID = 'androidApp',
}
