import { ImageUploader } from 'components/ImageUploader';
import EdiText from 'react-editext';

import { useSelector } from 'react-redux';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import s from './Testimonial.module.scss';

export interface ITestimonialProps {
  id: string;
  reviewText: string;
  reviewerName: string;
  // reviewCompanyName: string;
  userImage: string;
}

export const Testimonial: React.FC<ITestimonialProps> = (props) => {
  const { reviewText, userImage, reviewerName, id } = props;
  const isEditMode = useSelector(isEditModeSelector);

  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <div className={s.Testimonial}>
      <div className={s.Testimonial__bg}>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.Testimonial__decr}
          type={'text'}
          value={reviewText}
          onSave={(value) => handleSave(value, 'reviewText')}
        />
        <div className={s.Testimonial__name}>
          <div className={s.Testimonial__photo}>
            <ImageUploader src={userImage} alt='User avatar' nodeId={id} />
          </div>
          <div className={s.Testimonial__info}>
            <EdiText
              editButtonProps={{ hidden: !isEditMode }}
              className={s['Testimonial__first-name']}
              type={'text'}
              value={reviewerName}
              onSave={(value) => handleSave(value, 'reviewTitle')}
            />
            {/* <EdiText
              editButtonProps={{ hidden: !isEditMode }}
              className={'Testimonial__company-name'}
              type={'text'}
              value={reviewCompanyName}
              onSave={handleSave}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
