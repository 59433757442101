import { IWebsiteFlatTree } from './types';

export interface IWebsiteTree extends IWebsiteFlatTree {
  children: IWebsiteTree[];
}

/** Трансформирует из плоской структуры в древовидную вместе с children */
export const buildTree = (elems: IWebsiteFlatTree[]): IWebsiteTree[] => {
  const map: Record<string, number> = {};
  let node: IWebsiteTree | null = null;
  const roots = [];
  const list: IWebsiteTree[] = elems.map((elem) => ({ ...elem, children: [] }));

  for (let i = 0; i < list.length; i += 1) {
    map[list[i].id] = i;
  }

  for (let i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parent !== null) {
      list[map[node.child.context.parentId]].children.push(node);
    } else {
      roots.push(node);
    }
  }

  return roots;
};
