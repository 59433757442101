import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';

import rating from '../../images/rating1.svg';
import { Testimonial } from './components/Testimonial';
import type { ITestimonialProps } from './components/Testimonial';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { AVATARS } from 'constants/index';
import { useSelector } from 'react-redux';
import { isEditModeSelector } from 'slices/globalSlice';
import EdiText from 'react-editext';
import { ISectionProps } from 'types/props';
import s from './Testimonials.module.scss';

interface ITestimonialsProps extends ISectionProps {}

export const Testimonials: React.FC<ITestimonialsProps> = (props) => {
  const { section, children } = props;

  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const isEditMode = useSelector(isEditModeSelector);
  const [updateText] = useUpdateTextMutation();

  const reviewSectionContext = section?.child?.context;

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  const testimonialsSection: ITestimonialProps[] = section.children.map((elem, i) => ({
    reviewText: elem.child.context.reviewText,
    reviewerName: elem.child.context.reviewerName,
    id: elem.id,
    ratingImage: rating,
    userImage: elem.child.context.contentImageUrl || AVATARS[i],
    companyName: '{company name by GPT3.5}',
  }));

  return (
    <section className={s.Testimonials} id={section.id}>
      <div className='container'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.Testimonials__title}
          type={'text'}
          value={reviewSectionContext.testimonialsTitle || 'What Our  Client Says About Us'}
          onSave={(value) => handleSave(value, 'contentTitle')}
        />
        <Swiper
          loop
          spaceBetween={30}
          pagination={{
            type: 'bullets',
          }}
          navigation
          breakpoints={{
            1024: {
              slidesPerView: 2,
              initialSlide: 0,
            },
          }}
          modules={[Pagination, Navigation]}
        >
          {testimonialsSection.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <Testimonial {...testimonial} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {children}
    </section>
  );
};
