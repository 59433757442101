export const PAGES_URLS = {
  /** Дефолтный url */
  default: '/',
  /** Главная страница */
  homePage: '/:domain',
  /** Страница блога */
  blogPage: '/:domain/blog/:blogId',
  /** Страница terms */
  terms: '/:domain/terms',
  /** Страница privacy */
  privacy: '/:domain/privacy',
} as const;
