import avatar1 from 'assets/avatars/reviewer1.png';
import avatar2 from 'assets/avatars/reviewer2.png';
import avatar3 from 'assets/avatars/reviewer3.png';
import avatar4 from 'assets/avatars/reviewer4.png';
import avatar5 from 'assets/avatars/reviewer5.png';
import avatar6 from 'assets/avatars/reviewer6.png';
import avatar7 from 'assets/avatars/reviewer7.png';
import avatar8 from 'assets/avatars/reviewer8.png';
import avatar9 from 'assets/avatars/reviewer9.png';

export const AVATARS = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
];
