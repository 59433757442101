import React, { ChangeEvent, useState } from 'react';
import { isEditModeSelector } from 'slices/globalSlice';
import { useSelector } from 'react-redux';

import s from './ImageUploader.module.scss';
import { useUpdateImageMutation } from 'services/templateService';
import { Loading } from 'components/Loading';
import classNames from 'classnames';
import { IUpdateImageRequest } from 'services/templateService/types';

export interface ImageUploaderProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  nodeId: string;
  uploaderClassName?: string;
  contextImageParam?: string;
}

export const ImageUploader: React.FC<ImageUploaderProps> = (props) => {
  const { src, nodeId, uploaderClassName, contextImageParam, ...imgProps } = props;
  const [selectedImage, setSelectedImage] = useState<string>();
  const [selectedImageFile, setSelectedImageFile] = useState<File>();
  const isEditMode = useSelector(isEditModeSelector);
  const [updateImage, { data: uploadedImageData, isLoading }] = useUpdateImageMutation();

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) return;

    setSelectedImage(URL.createObjectURL(file));
    setSelectedImageFile(file);
  };

  const handleUpload = () => {
    if (selectedImage && selectedImageFile) {
      const body: IUpdateImageRequest = { nodeId, image: selectedImageFile, contextImageParam };
      updateImage(body);
    }
  };

  if (isLoading) return <Loading style={{ width: '100px', height: '100px' }} />;

  return (
    <div className={s.imageUploader}>
      <img src={uploadedImageData?.url || selectedImage || src} alt='Selected' {...imgProps} />
      {isEditMode && (
        <div
          data-imageupload='container'
          className={classNames(s.imageUploader__container, uploaderClassName)}
        >
          <label data-imageupload='label' className={s.imageUploader__label}>
            <input
              data-imageupload='input'
              type='file'
              accept='image/*'
              onChange={handleImageChange}
              className={s.imageUploader__input}
            />
            <div data-imageupload='text-container' className={s.imageUploader__placeholder}>
              <span data-imageupload='text'>Choose file</span>
            </div>
          </label>
          <button
            data-imageupload='btn'
            type='button'
            onClick={handleUpload}
            disabled={!selectedImage}
            className={s.imageUploader__btn}
          >
            Upload
          </button>
        </div>
      )}
    </div>
  );
};
