import EdiText from 'react-editext';
import { useSelector } from 'react-redux';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import { ISectionProps } from 'types/props';
import iphone from 'assets/mobileCTA/iphone-mockup.png';
import android from 'assets/mobileCTA/android-mockup.png';
import { ReactComponent as IosIcon } from 'assets/mobileCTA/ios.svg';
import { ReactComponent as AndroidIcon } from 'assets/mobileCTA/android.svg';
import { ImageUploader } from 'components/ImageUploader';

const ANDROID_TEXT = 'Get it on Google Play';
const IOS_TEXT = 'Get it on the App Store';

interface IMobileCTAProps extends ISectionProps {
  isAndroidSection?: boolean;
}

export const MobileCTA: React.FC<IMobileCTAProps> = (props) => {
  const { section, children, isAndroidSection = true } = props;
  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');
  const mobileCTASectionContext = section?.child?.context;
  const ctaText = isAndroidSection ? ANDROID_TEXT : IOS_TEXT;

  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const handleSave = (context: Record<string, string>, id: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context,
      },
    });
  };

  return (
    <section className='bg-white'>
      <div className='container flex flex-col items-center px-4 py-12 mx-auto xl:flex-row'>
        <div className='flex flex-col items-center mt-6 xl:items-start xl:w-1/2 xl:mt-0'>
          <EdiText
            editButtonProps={{ hidden: !isEditMode }}
            type={'text'}
            className='text-2xl font-semibold tracking-tight text-gray-800 xl:text-3xl'
            value={mobileCTASectionContext.appTitle}
            onSave={(value) => handleSave({ appTitle: value }, section.id)}
          />
          <EdiText
            editButtonProps={{ hidden: !isEditMode }}
            type={'text'}
            className='max-w-2xl mt-4 text-gray-500'
            value={mobileCTASectionContext.appDescription}
            onSave={(value) => handleSave({ appDescription: value }, section.id)}
          />
          <div className='mt-6 sm:-mx-2 group relative flex items-center flex-col'>
            <a
              {...(!isEditMode && { href: mobileCTASectionContext.appLink })}
              target={'_blank'}
              className='inline-flex items-center justify-center w-full px-4 text-sm py-2.5 overflow-hidden text-white transition-colors duration-300 rounded-lg shadow sm:w-auto sm:mx-2 focus:ring focus:ring-gray-300 focus:ring-opacity-80 no-underline btn bg-[color:rgb(var(--theme))]'
              rel='noreferrer'
            >
              {isAndroidSection ? <AndroidIcon /> : <IosIcon />}
              <EdiText
                editButtonProps={{ hidden: !isEditMode }}
                type={'text'}
                className='mx-2'
                value={mobileCTASectionContext.appCTA || ctaText}
                onSave={(value) => handleSave({ appCTA: value }, section.id)}
              />
            </a>
            {isEditMode && (
              <div
                className={
                  'opacity-0 group-hover:opacity-100 transition-opacity inline-block px-3 py-2  font-medium text-gray-900 bg-white border border-gray-200 rounded shadow'
                }
              >
                <span>Edit the button's link:</span>
                <EdiText
                  className='text-blue-600'
                  editButtonProps={{ hidden: !isEditMode }}
                  type={'text'}
                  value={mobileCTASectionContext.appLink}
                  onSave={(value) => handleSave({ appLink: value }, section.id)}
                />
              </div>
            )}
          </div>
        </div>
        <div className='justify-center xl:w-1/2 hidden md:flex'>
          <ImageUploader
            className='sm:w-[16rem] flex-shrink-0'
            src={mobileCTASectionContext.contentImageUrl || (isAndroidSection ? android : iphone)}
            alt='Phone'
            nodeId={section.id}
          />
        </div>
      </div>
      {children}
    </section>
  );
};

export const AndroidMobileCTA: React.FC<ISectionProps> = (props) => (
  <MobileCTA {...props} isAndroidSection />
);

export const IOSMobileCTA: React.FC<ISectionProps> = (props) => (
  <MobileCTA {...props} isAndroidSection={false} />
);
