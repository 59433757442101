import { ReactComponent as ServiceIcon } from '../../images/services-icon.svg';

import { Service, type IServiceProps } from './components/Service';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import { useSelector } from 'react-redux';
import EdiText from 'react-editext';
import s from './Services.module.scss';

import { ISectionProps } from 'types/props';

interface IServicesProps extends ISectionProps {}

export const Services: React.FC<IServicesProps> = (props) => {
  const { section, children } = props;
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const rootSection = data!.tree.find((elem) => elem.parent === null).child.context;
  const isEditMode = useSelector(isEditModeSelector);
  const [updateText] = useUpdateTextMutation();

  const serviceSectionContext = section?.child?.context;

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  const servicesSection: IServiceProps[] = section.children.map((elem) => ({
    id: elem.id,
    ServiceImage: ServiceIcon,
    itemTitle: elem.child.context.itemTitle,
    itemDescription: elem.child.context.itemDescription,
    contentImageUrl: elem.child.context.contentImageUrl,
  }));

  return (
    <section className={s.services} id={section.id}>
      <div className='container'>
        <h5 className='sub-title'>{rootSection.businessName}</h5>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={'title centerEditext'}
          type={'text'}
          value={serviceSectionContext.servicesTitle || 'Services we offer'}
          onSave={(value) => handleSave(value, 'servicesTitle')}
        />
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={'description centerEditext'}
          type={'text'}
          value={
            serviceSectionContext.servicesDescription || 'We are the experts in what we provide'
          }
          onSave={(value) => handleSave(value, 'servicesDescription')}
        />
        <div className={s.services__wrap}>
          {servicesSection.map((service) => (
            <Service key={service.id} {...service} />
          ))}
        </div>
      </div>
      {children}
    </section>
  );
};
