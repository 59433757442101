import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { EMode } from './types';

export const modeSelector = (state: RootState) => state.globalSlice.mode;

export const isEditModeSelector = createSelector(
  modeSelector,
  (mode) => mode === EMode.edit || mode === EMode.trial || mode === EMode.start
);
