import { RouterProvider } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isMode, setMode } from 'slices/globalSlice';
import { ROUTES } from 'constants/index';
import type { AppDispatch } from 'store';

import 'styles/main.scss';

// TODO Добавить общий компонент для загрузки лого и изменения edit mode в платной подписке
// TODO Перенести на next
// TODO Вынести компонент для редактирования в components/Editext как декоратор
// TODO Не обращаться напрямую в компонетах к редаксу, использовать пропсы

export const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { searchParams } = new URL(document.location.href);
  const mode = searchParams.get('mode');

  useEffect(() => {
    if (isMode(mode)) {
      dispatch(setMode(mode));
    }
  }, [mode, dispatch]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
  }, []);

  return <RouterProvider router={ROUTES} />;
};
