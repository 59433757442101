import EdiText from 'react-editext';

import { useSelector } from 'react-redux';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import { ImageUploader } from 'components/ImageUploader';
import s from './Advantage.module.scss';

export interface IAdvantageProps {
  id: string;
  AdvantageImage: React.FC<React.SVGProps<SVGSVGElement>>;
  itemTitle: string;
  itemDescription: string;
  contentImageUrl: string;
}

export const Advantage: React.FC<IAdvantageProps> = (props) => {
  const { itemDescription, itemTitle, id, AdvantageImage, contentImageUrl } = props;

  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <div className={s.advantage}>
      {contentImageUrl || isEditMode ? (
        <ImageUploader className={s.advantage__icon} src={contentImageUrl} alt='' nodeId={id} />
      ) : (
        <div className={s.advantage__icon}>{AdvantageImage && <AdvantageImage />}</div>
      )}
      <EdiText
        editButtonProps={{ hidden: !isEditMode }}
        className={s.advantage__heading}
        type={'text'}
        value={itemTitle}
        onSave={(value) => handleSave(value, 'itemTitle')}
      />
      <EdiText
        editButtonProps={{ hidden: !isEditMode }}
        className={s.advantage__descr}
        type={'text'}
        value={itemDescription}
        onSave={(value) => handleSave(value, 'itemDescription')}
      />
    </div>
  );
};
