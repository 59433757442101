import { createSelector } from '@reduxjs/toolkit';
import { HEADER_LINKS } from 'constants/index';
import { RootState } from 'store';

import { templateApi } from './api';
import { FixedTemplateBlockName } from './enums';
import { buildTree } from './utils';

export const selectGetTemplateQuery = (domain: string) =>
  createSelector(
    [(state: RootState) => templateApi.endpoints.getTemplate.select({ domain })(state)],
    (website) => website
  );

export const selectWebsite = (domain: string) =>
  createSelector(selectGetTemplateQuery(domain), (website) => website.data);

export const selectWebsiteTree = (domain: string) =>
  createSelector(selectGetTemplateQuery(domain), (website) => buildTree(website?.data?.tree ?? []));

export const selectRootSection = (domain: string) =>
  createSelector(selectWebsiteTree(domain), (tree) => tree[0]);

export const selectSortedSections = (domain: string) =>
  createSelector(selectWebsiteTree(domain), (tree) => {
    const sortedSections = (tree[0]?.children ?? [])
      .filter((copiedSection) =>
        Object.values(FixedTemplateBlockName).includes(copiedSection.child.name)
      )
      .sort((a, b) => a.child.order - b.child.order);

    return sortedSections;
  });

export const selectSectionIndexById = (id: string, domain: string) =>
  createSelector(selectSortedSections(domain), (sections) => {
    return sections.findIndex((section) => section.id === id);
  });

export const selectHeaderLinks = (domain: string) =>
  createSelector(selectSortedSections(domain), (sections) => {
    return sections
      .filter((block) => HEADER_LINKS.includes(block.child.name))
      .map((block) => ({
        id: block.id,
        name: block.child.name,
      }));
  });

export const selectBlogById = (domain: string, blogId: string) =>
  createSelector(selectSortedSections(domain), (sections) => {
    return sections
      .filter((section) => section.child.name === FixedTemplateBlockName.BLOG)
      .flatMap((blogSection) => blogSection.children)
      .find((blogSection) => blogSection.id === blogId);
  });
