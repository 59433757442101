import Gallery1 from 'assets/gallery/1.png';
import Gallery2 from 'assets/gallery/2.png';
import Gallery3 from 'assets/gallery/3.png';
import Gallery4 from 'assets/gallery/4.png';
import Gallery5 from 'assets/gallery/5.png';
import Gallery6 from 'assets/gallery/6.png';
import classNames from 'classnames';
import EdiText from 'react-editext';
import { useSelector } from 'react-redux';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import { ISectionProps } from 'types/props';

import { Fancybox } from 'components/Fancybox';
import s from './Gallery.module.scss';
import { ImageUploader } from '../../ImageUploader';

const PHOTOS = [Gallery1, Gallery2, Gallery3, Gallery4, Gallery5, Gallery6];

interface IGalleryProps extends ISectionProps {}

export const Gallery: React.FC<IGalleryProps> = (props) => {
  const { section, children } = props;
  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');
  const gallerySectionContext = section?.child?.context;

  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const handleSave = (context: Record<string, string>, id: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context,
      },
    });
  };

  return (
    <section className={s.Gallery__section} id={section.id}>
      <div className={'container'}>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          type={'text'}
          className='text-3xl text-gray-800 font-semibold centerEditext'
          value={gallerySectionContext.galleryTitle}
          onSave={(value) => handleSave({ galleryTitle: value }, section.id)}
        />
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          type={'text'}
          className='mt-3 text-gray-500 centerEditext'
          value={gallerySectionContext.gallerySubtitle}
          onSave={(value) => handleSave({ gallerySubtitle: value }, section.id)}
        />
        <Fancybox
          options={{
            Carousel: {
              infinite: false,
            },
          }}
        >
          <div className={s.Gallery__list}>
            {PHOTOS.map((src, i) => {
              const contextImageParam = `gallery${i}`;

              return (
                <div key={src} className={classNames(s.Gallery__item, '')}>
                  <a
                    {...(!isEditMode && {
                      'data-fancybox': 'gallery',
                      href: gallerySectionContext[contextImageParam] || src,
                    })}
                  >
                    <ImageUploader
                      src={gallerySectionContext[contextImageParam] || src}
                      alt={'Gallery element'}
                      nodeId={section.id}
                      uploaderClassName={s.Gallery__uploader}
                      contextImageParam={contextImageParam}
                    />
                  </a>
                </div>
              );
            })}
          </div>
        </Fancybox>
      </div>
      {children}
    </section>
  );
};
