import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type {
  IGetTemplateRequest,
  IGetTemplateResponse,
  IUpdateTextResponse,
  IUpdateTextRequest,
  IUpdateImageRequest,
  IUpdateImageResponse,
  IInsertBeforeRequest,
  IInsertBeforeResponse,
  IDeleteResponse,
  IDeleteRequest,
  IChangeOrderRequest,
  IChangeOrderResponse,
  IRegenerateResponse,
  IRegenerateRequest,
  IRegenerateComponentResponse,
  IRegenerateComponentRequest,
  INode,
} from './types';

const baseUrl =
  window.location.hostname === 'localhost'
    ? 'https://api.waive.dev'
    : `https:///${window.location.host.replace('web', 'api')}`;

export const templateApi = createApi({
  reducerPath: 'templates',
  baseQuery: fetchBaseQuery({ baseUrl }),
  tagTypes: ['Template'],
  endpoints: (builder) => ({
    getTemplate: builder.query<IGetTemplateResponse, IGetTemplateRequest>({
      query: (params) => ({
        url: `v4/websites/${params.domain}`,
      }),
      providesTags: ['Template'],
    }),
    updateText: builder.mutation<IUpdateTextResponse, IUpdateTextRequest>({
      query: (body) => ({
        url: 'v4/websites/node',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Template'],
    }),
    updateImage: builder.mutation<IUpdateImageResponse, IUpdateImageRequest>({
      query: ({ image, nodeId, contextImageParam }) => {
        const body = new FormData();
        body.append('Content-Type', image.type);
        body.append('file', image);

        if (contextImageParam) body.append('contextImageParam', contextImageParam);

        return {
          url: `v4/files/node/${nodeId}/upload`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Template'],
    }),
    insertBefore: builder.mutation<IInsertBeforeResponse, IInsertBeforeRequest>({
      query: (params) => {
        const { id, ...body } = params;
        return {
          url: `v4/websites/node/${id}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Template'],
    }),
    delete: builder.mutation<IDeleteResponse, IDeleteRequest>({
      query: (params) => ({
        url: `v4/websites/node/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Template'],
    }),
    regenerate: builder.mutation<IRegenerateResponse, IRegenerateRequest>({
      query: (body) => ({
        url: `v4/websites`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Template'],
    }),
    regenerateComponent: builder.mutation<
      IRegenerateComponentResponse,
      IRegenerateComponentRequest
    >({
      query: (body) => ({
        url: `v4/websites/node`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Template'],
    }),
    changeOrder: builder.mutation<IChangeOrderResponse, IChangeOrderRequest>({
      async queryFn(params, _queryApi, _extraOptions, baseQuery) {
        const [prevNode, nextNode] = params.nodes;
        const updatedPrevOrder: INode = { ...prevNode, order: nextNode.order };
        const updatedNextOrder: INode = { ...nextNode, order: prevNode.order };

        const promises = [updatedPrevOrder, updatedNextOrder].map((node) => {
          return baseQuery({
            url: `v4/websites/node`,
            body: {
              domain: params.domain,
              node,
            },
            method: 'PUT',
          });
        });

        return Promise.all(promises)
          .then((results) => {
            console.log('nodes updated', results);
            return { data: { ok: true } };
          })
          .catch((e) => {
            return { error: e };
          });
      },
      invalidatesTags: ['Template'],
    }),
  }),
});
