import { useMemo, useState } from 'react';
import { Link } from 'react-scroll';
import classnames from 'classnames';

import { ReactComponent as LogoIcon } from 'assets/logo.svg';
import { MobileMenu } from './components/MobileMenu';
import './header.scss';
import {
  FixedTemplateBlockName,
  selectHeaderLinks,
  selectRootSection,
  useGetTemplateQuery,
  useUpdateTextMutation,
} from 'services/templateService';
import {
  COLORS,
  COLORS_V4,
  COLORS_V3,
  COLORS_V5,
  FIXED_HEADER_HEIGHT,
  SRCOLL_DELAY,
  HEADER_LINK_NAMES,
} from 'constants/index';
import { ImageUploader } from 'components/ImageUploader';
import { ColorDropdown, DropdownOption } from 'components/ColorDropdown';
import { useSelector } from 'react-redux';
import { isEditModeSelector } from 'slices/globalSlice';
import { setCSSVariable } from 'utils/index';
import { ISectionProps } from 'types/props';
import EdiText from 'react-editext';
import { locationService } from '../../../services/locationService';

interface IHeaderProps extends ISectionProps {
  isBackView?: boolean;
}

export const Header: React.FC<IHeaderProps> = (props) => {
  const { section, children, template, isBackView = false } = props;
  const domain = window.location.pathname.split('/')[1];
  const isEditMode = useSelector(isEditModeSelector);
  const rootSection = useSelector(selectRootSection(domain));
  const links = useSelector(selectHeaderLinks(domain));
  const contactsSection = links.find((link) => link.name === FixedTemplateBlockName.CONTACTS);

  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const rootSectionContext = rootSection.child.context;
  const headerSectionContext = section?.child?.context;
  const [opened, setOpened] = useState(false);

  const colors = useMemo(() => {
    switch (template) {
      case 'template1':
      case 'template2':
        return COLORS;
      case 'template3':
        return COLORS_V3;
      case 'template4':
        return COLORS_V4;
      case 'template5':
        return COLORS_V5;
      default:
        return [];
    }
  }, [template]);

  const toggleHandler = () => {
    setOpened((prev) => !prev);
  };

  const handleGoBack = () => {
    locationService.goBack();
  };

  const handleSave = (context: Record<string, string>, id: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context,
      },
    });
  };

  const handleSelect = (option: DropdownOption) => {
    setCSSVariable('--theme', option.value);
    setCSSVariable('--secondaryTheme', option.secondary);

    handleSave(
      {
        themePrimaryColor: option.value,
        themeSecondaryColor: option.secondary,
      },
      rootSection.id
    );
  };

  return (
    <>
      <header className='header' id={section.id}>
        <div className='container'>
          <div className={classnames('header__logo', `header__logo--${template}`)}>
            {rootSectionContext.contentImageUrl || isEditMode ? (
              <ImageUploader
                src={rootSectionContext.contentImageUrl}
                alt=''
                nodeId={rootSection.id}
              />
            ) : (
              <LogoIcon />
            )}
            {headerSectionContext.businessName}
          </div>
          {!isBackView && (
            <nav className={classnames('header__menu', `header__menu--${template}`)}>
              <ul>
                {links.map((link) => (
                  <li key={link.id}>
                    <Link
                      activeClass='active'
                      smooth
                      spy
                      to={link.id}
                      offset={FIXED_HEADER_HEIGHT}
                      delay={SRCOLL_DELAY}
                    >
                      {HEADER_LINK_NAMES[link.name]}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          )}
          {isEditMode && <ColorDropdown options={colors} onSelect={handleSelect} />}
          {isBackView ? (
            <button
              className={classnames('header__btn', `header__btn--${template}`, 'border-none')}
              type='button'
              onClick={handleGoBack}
            >
              Go Back
            </button>
          ) : (
            <Link
              smooth
              spy
              to={isEditMode ? '' : contactsSection?.id ?? ''}
              offset={FIXED_HEADER_HEIGHT}
              delay={SRCOLL_DELAY}
              className={classnames('header__btn', `header__btn--${template}`)}
            >
              <EdiText
                editButtonProps={{ hidden: !isEditMode }}
                type={'text'}
                value={headerSectionContext.buttonCTA || 'Contact us'}
                onSave={(value) => handleSave({ buttonCTA: value }, section.id)}
              />
            </Link>
          )}
          {!isBackView && (
            <button
              className={classnames('mobile-menu-open', { active: opened })}
              type='button'
              aria-label='Open menu'
              onClick={toggleHandler}
            />
          )}
        </div>
        {children}
      </header>
      {!isBackView && <MobileMenu isOpened={opened} onToggle={toggleHandler} />}
    </>
  );
};
