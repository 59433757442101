import { generatePath, NavigateFunction } from 'react-router-dom';

import { PAGES_URLS } from 'constants/index';

class LocationService {
  protected navigate!: NavigateFunction;

  configure(navigate: NavigateFunction) {
    if (!this.navigate) this.navigate = navigate;
  }

  /** Вернуться назад */
  goBack = () => {
    this.navigate(-1);
  };

  /** Перейти на страницу блога> */
  goToBlogPage = (domain: string, blogId: string, state: any) => {
    const blogPath = generatePath(PAGES_URLS.blogPage, {
      blogId,
      domain,
    });

    this.navigate({ pathname: blogPath }, { state });
  };

  /** Перейти на страницу Terms */
  goToTerms = (domain: string) => {
    const termsPath = generatePath(PAGES_URLS.terms, { domain });

    this.navigate(termsPath);
  };

  /** Перейти на страницу Privacy> */
  goToPrivacy = (domain: string) => {
    const privacyPath = generatePath(PAGES_URLS.privacy, { domain });

    this.navigate(privacyPath);
  };

  /** Перейти на главную страницу> */
  goToHomepage = (domain: string, blogId: string) => {
    const blogPath = generatePath(PAGES_URLS.blogPage, {
      blogId,
      domain,
    });

    this.navigate(blogPath);
  };
}

export const locationService = new LocationService();
