import EdiText from 'react-editext';
import s from './Advantage.module.scss';
import { isEditModeSelector } from 'slices/globalSlice';
import { useSelector } from 'react-redux';
import {
  FixedTemplateBlockName,
  selectHeaderLinks,
  useGetTemplateQuery,
  useUpdateTextMutation,
} from 'services/templateService';
import { Button } from 'components/Button';

export interface IAdvantageProps {
  id: string;
  itemTitle: string;
  itemDescription: string;
  itemCTA: string;
  itemCTALink: string;
}

export const Advantage: React.FC<IAdvantageProps> = (props) => {
  const { itemTitle, itemDescription, id, itemCTA, itemCTALink } = props;
  const isEditMode = useSelector(isEditModeSelector);

  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();
  const links = useSelector(selectHeaderLinks(domain));
  const contactsSection = links.find((link) => link.name === FixedTemplateBlockName.CONTACTS);

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <div className={s.advantage}>
      <div className={s.advantage__bg}>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.advantage__heading}
          type={'text'}
          value={itemTitle}
          onSave={(value) => handleSave(value, 'itemTitle')}
        />
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.advantage__descr}
          type={'text'}
          value={itemDescription}
          onSave={(value) => handleSave(value, 'itemDescription')}
        />
        <Button
          className={s.advantage__btn}
          id={id}
          btnValue={itemCTA || 'Find out more'}
          btnLink={itemCTALink}
          btnPropName={'itemCTA'}
          btnLinkPropName={'itemCTALink'}
          to={contactsSection?.id ?? ''}
        />
      </div>
    </div>
  );
};
