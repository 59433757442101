import { useSelector } from 'react-redux';
import {
  FixedTemplateBlockName,
  selectSortedSections,
  useGetTemplateQuery,
} from 'services/templateService';
import { ScrollRestoration, useParams } from 'react-router-dom';
import { TRouteParams } from 'types/routes';
import { Header } from 'components/sections/Header';
import { Loading } from 'components/Loading';

export const PrivacyPage: React.FC = () => {
  const { domain } = useParams<TRouteParams>();

  if (!domain) return null;

  const { isLoading } = useGetTemplateQuery({ domain });

  const header = useSelector(selectSortedSections(domain)).find(
    (section) => section.child.name === FixedTemplateBlockName.HEADER
  );

  if (isLoading) return <Loading />;
  if (!header) return null;

  return (
    <>
      <Header section={header} template={header.child.type} isBackView />
      <main className={'pt-12'}>
        <div className='px-4 sm:px-6 lg:px-8'>
          <div className='relative mx-auto max-w-[37.5rem] text-left pb-24'>
            <h1 className='text-4xl font-extrabold tracking-tight text-slate-900 sm:text-5xl text-center'>
              Privacy policy
            </h1>
            <p className='mt-4 text-base leading-7 text-slate-600 text-center'>
              Last updated on November 2, 2021
            </p>
            <p className={'lead mt-3 mb-3 text-gray-500 pt-20'}>
              Welcome to [Your Company Name] ("Company," "we," "our," or "us"). This Privacy and
              Cookies Policy ("Policy") explains how we collect, use, disclose, and protect your
              personal information when you use our website. By accessing or using our website, you
              consent to the practices described in this Policy.
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>Information We Collect</h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              a. <b>Personal Information</b>: We may collect personal information you provide
              directly to us, such as your name, email address, and contact information when you
              interact with our website.
            </p>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              b. <b>Usage Information</b>: We may collect information about your usage of the
              website, including your IP address, browser type, device information, pages visited,
              and interactions with the website.
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>Use of Information</h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              a. We use the collected information to provide, operate, and improve our website,
              communicate with you, respond to your inquiries, and personalize your experience.
            </p>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              b. We may use your email address to send you promotional materials or updates about
              our services, but you can opt-out of receiving such communications.
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>
              Cookies and Tracking Technologies
            </h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              a. We and our third-party partners may use cookies and similar tracking technologies
              to collect information about your interactions with the website and to enhance your
              experience.
            </p>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              b. You can control cookies through your browser settings. Please note that disabling
              cookies may affect your ability to use certain features of the website.
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>Disclosure of Information</h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              a. We may share your personal information with trusted third-party service providers
              who assist us in operating our website and conducting our business.
            </p>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              b. We may also disclose your information to comply with legal obligations, enforce our
              rights, and protect the safety and security of our users and the public.
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>Data Security</h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              We implement reasonable security measures to protect your personal information from
              unauthorized access and disclosure. However, no data transmission over the internet is
              completely secure, and we cannot guarantee the security of your information.
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>Third-Party Links</h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              Our website may contain links to third-party websites or services. This Policy does
              not apply to those third-party sites, and we are not responsible for their privacy
              practices. We encourage you to review the privacy policies of those sites before
              providing any personal information.
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>Children's Privacy</h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              Our website is not intended for children under the age of 13. We do not knowingly
              collect or store personal information from children under 13 without parental consent.
              If you believe we have inadvertently collected such information, please contact us to
              have it removed.
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>Changes to this Policy</h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              We may update this Policy from time to time. Any changes will be effective when we
              post the revised Policy on our website. Your continued use of the website after the
              changes have been made constitutes your acceptance of the revised Policy.
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>Contact Us</h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              If you have any questions or concerns about this Privacy and Cookies Policy, please
              contact us at [contact email address].
            </p>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              By using our website, you consent to the practices described in this Policy. Thank you
              for choosing [Your Company Name]!
            </p>
          </div>
        </div>
      </main>
      <ScrollRestoration />
    </>
  );
};
