import { FixedTemplateBlockName } from 'services/templateService';

export const FIXED_HEADER_HEIGHT = -65;
export const FIXED_MOBILE_HEADER_HEIGHT = -68;
export const SRCOLL_DELAY = 200;

export const HEADER_LINKS = [
  FixedTemplateBlockName.ABOUT,
  FixedTemplateBlockName.SERVICES,
  FixedTemplateBlockName.TESTIMONIALS,
  FixedTemplateBlockName.ADVANTAGES,
  FixedTemplateBlockName.CONTACTS,
];

export const HEADER_LINK_NAMES: { [key in FixedTemplateBlockName]?: string } = {
  [FixedTemplateBlockName.ABOUT]: 'Home',
  [FixedTemplateBlockName.SERVICES]: 'Services',
  [FixedTemplateBlockName.TESTIMONIALS]: 'Testimonials',
  [FixedTemplateBlockName.ADVANTAGES]: 'Advantages',
  [FixedTemplateBlockName.CONTACTS]: 'Contacts',
};
