import { createBrowserRouter } from 'react-router-dom';
import { Homepage } from 'routes/Homepage';
import { BlogPage } from 'routes/BlogPage';
import { PrivacyPage } from 'routes/PrivacyPage';
import { TermsPage } from 'routes/TermsPage';
import { NotFound } from 'components/NotFound';
import { PAGES_URLS } from './urls';

export const ROUTES = createBrowserRouter([
  {
    path: PAGES_URLS.default,
    element: <NotFound />,
  },
  {
    path: PAGES_URLS.homePage,
    element: <Homepage />,
  },
  {
    path: PAGES_URLS.blogPage,
    element: <BlogPage />,
  },
  {
    path: PAGES_URLS.terms,
    element: <TermsPage />,
  },
  {
    path: PAGES_URLS.privacy,
    element: <PrivacyPage />,
  },
]);
