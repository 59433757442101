import advantagesIcon1 from '../../images/calendar.svg';
import advantagesIcon2 from '../../images/shield.svg';
import advantagesIcon3 from '../../images/check.svg';
import { Advantage, type IAdvantageProps } from './components/Advantage';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { useSelector } from 'react-redux';
import { isEditModeSelector } from 'slices/globalSlice';
import EdiText from 'react-editext';
import { ISectionProps } from 'types/props';
import s from './Advantages.module.scss';
import classNames from 'classnames';

const ADVANTAGE_ICONS: Record<string, string> = {
  advantagesIcon1,
  advantagesIcon2,
  advantagesIcon3,
};

interface IAdvantagesProps extends ISectionProps {}

export const Advantages: React.FC<IAdvantagesProps> = (props) => {
  const { section, children } = props;
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const isEditMode = useSelector(isEditModeSelector);
  const [updateText] = useUpdateTextMutation();

  const rootSection = data!.tree.find((elem) => elem.parent === null).child.context;
  const advantageSectionContext = section.child.context;

  const advServicesSection: IAdvantageProps[] = section.children.map((elem, i) => ({
    id: elem.id,
    itemTitle: elem.child.context.itemTitle,
    itemDescription: elem.child.context.itemDescription,
    contentImageUrl: elem.child.context.contentImageUrl,
    advantageImage: elem.child.context.contentImage || ADVANTAGE_ICONS[`advantagesIcon${i + 1}`],
  }));

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <section className={s.advantages} id={section.id}>
      <div className='container'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={classNames(s.advantages__title, 'title')}
          type={'text'}
          value={
            advantageSectionContext.contentTitle ||
            `Why Choose ${rootSection.businessName}: Our Distinct Advantages`
          }
          onSave={(value) => handleSave(value, 'contentTitle')}
        />
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={classNames(s.advantages__description, 'description')}
          type={'text'}
          value={
            advantageSectionContext.contentDescription ||
            `Explore the unique benefits and competitive edge that set us apart in the ever-evolving landscape of the ${rootSection.industry}`
          }
          onSave={(value) => handleSave(value, 'contentDescription')}
        />
        <div className={s.advantages__wrap}>
          {advServicesSection.map((advantage) => (
            <Advantage key={advantage.id} {...advantage} />
          ))}
        </div>
      </div>
      {children}
    </section>
  );
};
