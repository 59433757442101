import { Header } from 'components/sections/Header';
import { ScrollRestoration, useParams } from 'react-router-dom';
import { TRouteParams } from 'types/routes';
import { useSelector } from 'react-redux';
import {
  FixedTemplateBlockName,
  selectSortedSections,
  useGetTemplateQuery,
} from 'services/templateService';
import { Loading } from 'components/Loading';

export const TermsPage: React.FC = () => {
  const { domain } = useParams<TRouteParams>();

  if (!domain) return null;

  const { isLoading } = useGetTemplateQuery({ domain });

  const header = useSelector(selectSortedSections(domain)).find(
    (section) => section.child.name === FixedTemplateBlockName.HEADER
  );

  if (isLoading) return <Loading />;
  if (!header) return null;

  return (
    <>
      <Header section={header} template={header.child.type} isBackView />
      <main className={'pt-12'}>
        <div className='px-4 sm:px-6 lg:px-8'>
          <div className='relative mx-auto max-w-[37.5rem] text-left pb-24'>
            <h1 className='text-4xl font-extrabold tracking-tight text-slate-900 sm:text-5xl text-center'>
              User Terms
            </h1>
            <p className='mt-4 text-base leading-7 text-slate-600 text-center'>
              Effective Date: 31 Aug 2023
            </p>
            <p className={'lead mt-3 mb-3 text-gray-500 pt-20'}>
              Welcome to [Your Company Name] ("Company," "we," "our," or "us")! Please read these
              Small Business Website User Terms ("Terms") carefully before using our website. By
              accessing or using our website, you agree to be bound by these Terms. If you do not
              agree with any part of these Terms, please do not use our website.
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>Acceptance of Terms</h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              By accessing or using our website, you acknowledge that you have read, understood, and
              agree to comply with these Terms, along with our Privacy Policy and any other
              applicable policies, which are incorporated herein by reference.
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>Changes to Terms</h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              We reserve the right to update or modify these Terms at any time without prior notice.
              Your continued use of the website after such changes constitutes your acceptance of
              the revised Terms.
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>Use of Website</h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              a. You may use our website for lawful purposes only. You are prohibited from using the
              website for any unlawful, fraudulent, or unauthorized purposes.
            </p>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              b. You may not use the website to transmit or upload any harmful or malicious content,
              including viruses, spyware, or any other software or code that may damage or interfere
              with the operation of the website.{' '}
            </p>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              c. You agree not to interfere with the security or accessibility of the website, or
              engage in any activity that disrupts or interferes with other users' use of the
              website.{' '}
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>User Content</h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              a. You may be able to post, submit, or upload content to the website. By submitting
              such content, you grant us a non-exclusive, royalty-free, worldwide, perpetual, and
              irrevocable license to use, reproduce, modify, adapt, publish, translate, distribute,
              and display such content in any media.
            </p>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              b. You are solely responsible for the content you post on the website. You represent
              and warrant that you have all necessary rights to the content you submit and that the
              content does not violate any third-party rights or laws.{' '}
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>Intellectual Property</h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              All content on the website, including but not limited to text, graphics, logos,
              images, and software, is the property of the Company or its licensors and is protected
              by intellectual property laws. You may not use, reproduce, distribute, or modify any
              content without our prior written consent.
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>Limitation of Liability</h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              To the extent permitted by law, we shall not be liable for any indirect, incidental,
              special, consequential, or punitive damages arising out of or in connection with your
              use of the website.
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>Indemnification</h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              You agree to indemnify, defend, and hold us harmless from any claims, damages, losses,
              liabilities, and expenses (including attorneys' fees) arising out of or related to
              your use of the website, violation of these Terms, or infringement of any third-party
              rights.
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>
              Governing Law and Jurisdiction
            </h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              These Terms shall be governed by and construed in accordance with the laws of the
              State of [State Name], without regard to its conflict of law principles. Any legal
              action or proceeding arising out of or relating to these Terms shall be brought
              exclusively in the state or federal courts located in [County], [State Name].
            </p>
            <h2 className={'text-xl font-bold text-gray-900 mb-4'}>Contact Us</h2>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              If you have any questions or concerns about these Terms, please contact us at [contact
              email address].
            </p>
            <p className={'lead mt-3 mb-3 text-gray-500'}>
              By using our website, you agree to these Terms. Thank you for choosing [Your Company
              Name]!
            </p>
          </div>
        </div>
      </main>
      <ScrollRestoration />
    </>
  );
};
