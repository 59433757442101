import { useSelector } from 'react-redux';
import classnames from 'classnames';
import EdiText from 'react-editext';

import linkedin from 'assets/footer/linkedin.svg';
import fb from 'assets/footer/fb.svg';
import hz from 'assets/footer/hz.svg';
import { ReactComponent as LogoIcon } from 'assets/logo.svg';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { ISectionProps } from 'types/props';
import { isEditModeSelector } from 'slices/globalSlice';
import { locationService } from 'services/locationService';
import { PAGES_URLS } from 'constants/index';
import { Tooltip } from 'components/Tooltip';
import './footer.scss';

interface IFooterProps extends ISectionProps {}

export const Footer: React.FC<IFooterProps> = (props) => {
  const { section, children, template } = props;
  const isEditMode = useSelector(isEditModeSelector);

  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const rootSection = data!.tree.find((elem) => elem.parent === null);
  const rootSectionContext = rootSection.child.context;

  const footerSectionContext = section?.child?.context;

  const handleGoTermsPage = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    if (isEditMode) return;
    locationService.goToTerms(domain);
  };

  const handleGoPrivacyPage = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    if (isEditMode) return;
    locationService.goToPrivacy(domain);
  };

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <footer className='footer'>
      <div className='container'>
        <div className='footer__foot'>
          <a href='/' className={classnames('header__logo', `header__logo--${template}`)}>
            {rootSectionContext.contentImageUrl ? (
              <img src={rootSectionContext.contentImageUrl} alt='logo' />
            ) : (
              <LogoIcon />
            )}{' '}
            {rootSectionContext.businessName}
          </a>
          <ul className={classnames('footer__menu', `footer__menu--${template}`)}>
            <li>
              <a href={PAGES_URLS.terms} onClick={handleGoTermsPage}>
                <EdiText
                  editButtonProps={{ hidden: !isEditMode }}
                  type={'text'}
                  value={footerSectionContext.terms || 'Terms'}
                  onSave={(value) => handleSave(value, 'terms')}
                />
              </a>
            </li>
            <li>
              <a href={PAGES_URLS.privacy} onClick={handleGoPrivacyPage}>
                <EdiText
                  editButtonProps={{ hidden: !isEditMode }}
                  type={'text'}
                  value={footerSectionContext.privacy || 'Privacy'}
                  onSave={(value) => handleSave(value, 'privacy')}
                />
              </a>
            </li>
            <li>
              <a>
                <EdiText
                  editButtonProps={{ hidden: !isEditMode }}
                  type={'text'}
                  value={footerSectionContext.cookies || 'Cookies'}
                  onSave={(value) => handleSave(value, 'cookies')}
                />
              </a>
            </li>
          </ul>
          <ul className='footer__socials'>
            <li>
              <Tooltip
                id={section.id}
                link={footerSectionContext.linkedinLink}
                linkPropName={'linkedinLink'}
                containerClassName={'footer__tooltipPosition'}
              >
                <a href={footerSectionContext.linkedinLink} target='_blank' rel='noreferrer'>
                  <img src={linkedin} alt='' />
                </a>
              </Tooltip>
            </li>
            <li>
              <Tooltip
                id={section.id}
                link={footerSectionContext.fbLink}
                linkPropName={'fbLink'}
                containerClassName={'footer__tooltipPosition'}
              >
                <a href={footerSectionContext.fbLink} target='_blank' rel='noreferrer'>
                  <img src={fb} alt='' />
                </a>
              </Tooltip>
            </li>
            <li>
              <Tooltip
                id={section.id}
                link={footerSectionContext.hzLink}
                linkPropName={'hzLink'}
                containerClassName={'footer__tooltipPosition'}
              >
                <a href={footerSectionContext.hzLink} target='_blank' rel='noreferrer'>
                  <img src={hz} alt='' />
                </a>
              </Tooltip>
            </li>
          </ul>
        </div>
      </div>
      {children}
    </footer>
  );
};
