import EdiText from 'react-editext';
import { useSelector } from 'react-redux';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import { ImageUploader } from 'components/ImageUploader';

import s from './Service.module.scss';

export interface IServiceProps {
  id: string;
  ServiceImage: React.FC<React.SVGProps<SVGSVGElement>>;
  itemTitle: string;
  itemDescription: string;
  contentImageUrl: string;
}

export const Service: React.FC<IServiceProps> = (props) => {
  const { ServiceImage, itemTitle, itemDescription, id, contentImageUrl } = props;
  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();
  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <div className={s.service}>
      <span className={s.service__hover}>
        {contentImageUrl || isEditMode ? (
          <div className={s.service__icon}>
            <ImageUploader src={contentImageUrl} alt='' nodeId={id} />
          </div>
        ) : (
          <div className={s.service__icon}>{ServiceImage && <ServiceImage />}</div>
        )}
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.service__title}
          type={'text'}
          value={itemTitle}
          onSave={(value) => handleSave(value, 'itemTitle')}
        />
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.service__descr}
          type={'text'}
          value={itemDescription}
          onSave={(value) => handleSave(value, 'itemDescription')}
        />
        <span className={s.service__link}>
          Learn more
          <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M10.45 2.95 17.5 10l-7.05 7.05M17.5 10h-15'
              stroke='#000'
              strokeWidth='1.4'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </span>
      </span>
    </div>
  );
};
