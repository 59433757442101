import classNames from 'classnames';
import EdiText from 'react-editext';
import { useSelector } from 'react-redux';
import {
  FixedTemplateBlockName,
  selectHeaderLinks,
  useGetTemplateQuery,
  useUpdateTextMutation,
} from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import { ISectionProps } from 'types/props';
import s from './Pricing.module.scss';
import { Link } from 'react-scroll';
import { FIXED_HEADER_HEIGHT, SRCOLL_DELAY } from 'constants/index';

interface IPricingProps extends ISectionProps {}

export const Pricing: React.FC<IPricingProps> = (props) => {
  const { section, children } = props;
  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');
  const pricingSectionContext = section?.child?.context;
  const links = useSelector(selectHeaderLinks(domain));
  const contactsSection = links.find((link) => link.name === FixedTemplateBlockName.CONTACTS);

  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const handleSave = (context: Record<string, string>, id: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context,
      },
    });
  };

  const plans = section.children.map((elem) => ({
    id: elem.id,
    itemTitle: elem.child.context.itemTitle,
    itemDescription: elem.child.context.itemDescription,
    itemPrice: elem.child.context.itemPrice,
    buttonCTA: elem.child.context.buttonCTA,
  }));

  return (
    <section className='py-14' id={section.id}>
      <div className='max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8'>
        <div className='relative max-w-xl mx-auto sm:text-center'>
          <EdiText
            editButtonProps={{ hidden: !isEditMode }}
            type={'text'}
            className='text-gray-800 text-3xl font-semibold sm:text-4xl centerEditext'
            value={pricingSectionContext.pricingTitle}
            onSave={(value) => handleSave({ pricingTitle: value }, section.id)}
          />
          <div className='mt-3 max-w-xl'>
            <EdiText
              editButtonProps={{ hidden: !isEditMode }}
              type={'text'}
              className='centerEditext'
              value={pricingSectionContext.pricingDescription}
              onSave={(value) => handleSave({ pricingDescription: value }, section.id)}
            />
          </div>
        </div>
        <div className='mt-16 space-y-6 justify-center gap-6 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-3'>
          {plans.map((plan) => (
            <div
              key={plan.id}
              className={classNames(
                s.Pricing__card,
                'relative flex-1 flex items-stretch flex-col p-8 rounded-xl border-2'
              )}
            >
              <div>
                <EdiText
                  editButtonProps={{ hidden: !isEditMode }}
                  type={'text'}
                  className='font-medium text-[color:rgb(var(--theme))]'
                  value={plan.itemTitle}
                  onSave={(value) => handleSave({ itemTitle: value }, plan.id)}
                />
                <div className='mt-4 text-gray-800 text-3xl font-semibold'>
                  <EdiText
                    editButtonProps={{ hidden: !isEditMode }}
                    type={'text'}
                    value={plan.itemPrice}
                    onSave={(value) => handleSave({ itemPrice: value }, plan.id)}
                  />
                </div>
              </div>
              <ul className='py-8 space-y-3'>
                <EdiText
                  editButtonProps={{ hidden: !isEditMode }}
                  type={'text'}
                  className=''
                  value={plan.itemDescription}
                  onSave={(value) => handleSave({ itemDescription: value }, plan.id)}
                />
              </ul>
              <div className='flex-1 flex items-end'>
                <Link
                  smooth
                  spy
                  to={isEditMode ? '' : contactsSection?.id ?? ''}
                  offset={FIXED_HEADER_HEIGHT}
                  delay={SRCOLL_DELAY}
                  className='px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 text-white bg-[color:rgb(var(--theme))] flex justify-center no-underline items-center'
                >
                  <EdiText
                    editButtonProps={{ hidden: !isEditMode }}
                    type={'text'}
                    value={plan.buttonCTA}
                    onSave={(value) => handleSave({ buttonCTA: value }, plan.id)}
                  />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      {children}
    </section>
  );
};
