import classNames from 'classnames';
import EdiText from 'react-editext';
import { useSelector } from 'react-redux';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import { ISectionProps } from 'types/props';
import './form.scss';

interface IFormProps extends ISectionProps {}

export const Form: React.FC<IFormProps> = (props) => {
  const { template, children, section } = props;
  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const formSectionContext = section?.child?.context;

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <section className='form template-form' id={section.id}>
      <div className='container'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          type={'text'}
          value={formSectionContext.formDescription}
          onSave={(value) => handleSave(value, 'formDescription')}
          className='descr centerEditext'
        />
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          type={'text'}
          value={formSectionContext.formTitle}
          onSave={(value) => handleSave(value, 'formTitle')}
          className='title centerEditext'
        />
        <form action=''>
          <input type='text' placeholder='NAME' name='name' required />
          <input type='text' placeholder='MOBILE' name='phone' required />
          <input type='email' placeholder='EMAIL' name='email' required />
          <textarea name='message' placeholder='MESSAGE' required />
          <input
            className={classNames('form__btn', `form__btn--${template}`)}
            type='submit'
            value={formSectionContext.formCTA}
          />
        </form>
      </div>
      {children}
    </section>
  );
};
