import { useSelector } from 'react-redux';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import { ISectionProps } from 'types/props';
import { FaqsCard } from './components/FaqsCard';
import EdiText from 'react-editext';

interface IFaqsProps extends ISectionProps {}

export const Faqs: React.FC<IFaqsProps> = (props) => {
  const { section, children } = props;
  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');
  const faqsSectionContext = section?.child?.context;

  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const handleSave = (context: Record<string, string>, id: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context,
      },
    });
  };

  const faqs = section.children.map((elem) => ({
    id: elem.id,
    itemTitle: elem.child.context.itemTitle,
    itemAnswer: elem.child.context.itemAnswer,
  }));

  return (
    <section className='leading-relaxed mx-auto px-4 md:px-8' id={section.id}>
      <div className='space-y-3 text-center'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          type={'text'}
          className='text-3xl text-gray-800 font-semibold centerEditext'
          value={faqsSectionContext.faqTitle}
          onSave={(value) => handleSave({ faqTitle: value }, section.id)}
        />
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          type={'text'}
          className='text-gray-600 max-w-lg mx-auto text-lg centerEditext'
          value={faqsSectionContext.faqDescription}
          onSave={(value) => handleSave({ faqDescription: value }, section.id)}
        />
      </div>
      <div className='mt-14 max-w-2xl mx-auto'>
        {faqs.map((faq) => (
          <FaqsCard
            key={faq.id}
            question={faq.itemTitle}
            answer={faq.itemAnswer}
            faqsCardId={faq.id}
          />
        ))}
      </div>
      {children}
    </section>
  );
};
