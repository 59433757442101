import Marquee from 'react-fast-marquee';
import { ISectionProps } from 'types/props';
import './crossAnimation.scss';
import { ReactComponent as StarIcon } from '../../images/star.svg';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { isEditModeSelector } from 'slices/globalSlice';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import EdiText from 'react-editext';

const ANIMATION_SPEED = 200;

interface ICrossAnimationProps extends ISectionProps {}

export const CrossAnimation: React.FC<ICrossAnimationProps> = (props) => {
  const { section, children } = props;
  const [speed, setSpeed] = useState(ANIMATION_SPEED);
  const isEditMode = useSelector(isEditModeSelector);
  const [updateText] = useUpdateTextMutation();
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });

  const animations = section.children.map((animation) => ({
    id: animation.id,
    animationText: animation.child.context.animationText,
  }));

  const handleSave = (val: string, propName: string, id: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context: {
          [propName]: val,
        },
      },
    });
  };
  const editModeProps = {
    onMouseEnter: () => setSpeed(0),
    onMouseLeave: () => setSpeed(ANIMATION_SPEED),
  };

  return (
    <section className='crossAnimation' id={section.id} {...(isEditMode && editModeProps)}>
      <div className='brand crossAnimation__head'>
        <Marquee speed={speed}>
          {animations.map((animation) => (
            <div key={animation.id} className='crossAnimation__item'>
              {animation.animationText}
              <EdiText
                editButtonProps={{ hidden: !isEditMode }}
                type={'text'}
                value={animation.animationText}
                onSave={(value) => handleSave(value, 'animationText', animation.id)}
              />
              <StarIcon className='crossAnimation__icon' />
            </div>
          ))}
        </Marquee>
      </div>
      <div className='brand crossAnimation__foot'>
        <Marquee speed={speed}>
          {animations.map((animation) => (
            <div key={animation.id} className='crossAnimation__item'>
              <EdiText
                editButtonProps={{ hidden: !isEditMode }}
                type={'text'}
                value={animation.animationText}
                onSave={(value) => handleSave(value, 'animationText', animation.id)}
              />
              <StarIcon className='crossAnimation__icon' />
            </div>
          ))}
        </Marquee>
      </div>
      {children}
    </section>
  );
};
