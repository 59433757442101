/** Цвета для шаблона v1, v2 */
export const COLORS = [
  { value: '255, 100, 98', secondary: '255, 65, 62', label: 'Red' },
  { value: '53, 99, 233', secondary: '27, 79, 230', label: 'Blue' },
  { value: '154, 79, 228', secondary: '138, 52, 223', label: 'Purple' },
  { value: '0, 135, 61', secondary: '0, 121, 54', label: 'Green' },
  { value: '218, 165, 32', secondary: '196, 148, 28', label: 'Yellow' },
  { value: '54, 69, 79', secondary: '48, 62, 71', label: 'Grey' },
];

/** Цвета для шаблона v3 */
export const COLORS_V3 = [
  { value: '53, 99, 233', secondary: '46, 42, 79', label: 'Blue' },
  { value: '255, 100, 98', secondary: '50, 47, 66', label: 'Red' },
  { value: '154, 79, 228', secondary: '28, 23, 64', label: 'Purple' },
  { value: '43, 171, 101', secondary: '50, 47, 66', label: 'Green' },
  { value: '248, 223, 1', secondary: '50, 47, 66', label: 'Yellow' },
  { value: '147, 157, 164', secondary: '40, 39, 46', label: 'Grey' },
];

/** Цвета для шаблона v4 */
export const COLORS_V4 = [
  { value: '53, 99, 233', secondary: '46, 42, 79', label: 'Blue' },
  { value: '255, 100, 98', secondary: '50, 47, 66', label: 'Red' },
  { value: '154, 79, 228', secondary: '28, 23, 64', label: 'Purple' },
  { value: '43, 171, 101', secondary: '50, 47, 66', label: 'Green' },
  { value: '223, 144, 86', secondary: '248, 222, 3', label: 'Yellow' },
  { value: '147, 157, 164', secondary: '40, 39, 46', label: 'Grey' },
];

/** Цвета для шаблона v5 */
export const COLORS_V5 = [
  { value: '53, 99, 233', secondary: '255, 100, 98', label: 'Blue' },
  { value: '224, 102, 100', secondary: '50, 47, 66', label: 'Red' },
  { value: '132, 66, 197', secondary: '251, 196, 60', label: 'Purple' },
  { value: '81, 161, 117', secondary: '195, 209, 70', label: 'Green' },
  { value: '251, 196, 60', secondary: '140, 109, 193', label: 'Yellow' },
  { value: '60, 60, 60', secondary: '102, 159, 212', label: 'Grey' },
];
