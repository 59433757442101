import classNames from 'classnames';
import EdiText from 'react-editext';
import { useSelector } from 'react-redux';
import {
  FixedTemplateBlockName,
  selectHeaderLinks,
  useGetTemplateQuery,
  useUpdateTextMutation,
} from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import { ISectionProps } from 'types/props';
import s from './Head.module.scss';
import { Button } from 'components/Button';

interface IHeadProps extends ISectionProps {}

export const Head: React.FC<IHeadProps> = (props) => {
  const { section, children, template } = props;
  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();
  const links = useSelector(selectHeaderLinks(domain));
  const contactsSection = links.find((link) => link.name === FixedTemplateBlockName.CONTACTS);

  const aboutUsSectionContext = section?.child?.context;

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <section className={s.head} id={section.id}>
      <div className='container'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.head__title}
          type={'text'}
          value={
            aboutUsSectionContext.aboutTitle ||
            `${aboutUsSectionContext.businessName}: Revolutionising ${aboutUsSectionContext.industry} in ${aboutUsSectionContext.city}`
          }
          onSave={(value) => handleSave(value, 'aboutTitle')}
        />
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.head__description}
          type={'text'}
          value={aboutUsSectionContext.aboutDescription}
          onSave={(value) => handleSave(value, 'aboutDescription')}
        />
        <Button
          className={classNames('btn', `btn--${template}`)}
          id={section.id}
          btnValue={aboutUsSectionContext.aboutCTA}
          btnLink={aboutUsSectionContext.aboutLink}
          btnPropName={'aboutCTA'}
          btnLinkPropName={'aboutLink'}
          to={contactsSection?.id ?? ''}
        />
      </div>
      {children}
    </section>
  );
};
