import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from './types';

const initialState: GlobalState = {
  mode: null,
  theme: null,
};

/* eslint-disable no-param-reassign */
export const globalSlice = createSlice({
  name: 'globalSlice',
  initialState,
  reducers: {
    setMode: (state, action: PayloadAction<GlobalState['mode']>) => {
      state.mode = action.payload;
    },
    setTheme: (state, action: PayloadAction<GlobalState['theme']>) => {
      state.theme = action.payload;
    },
  },
});

export const { setMode } = globalSlice.actions;
