import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import 'swiper/css';

import { ReactComponent as ServiceIcon1 } from '../../images/slide-1.svg';
import { ReactComponent as ServiceIcon2 } from '../../images/slide-2.svg';
import { ReactComponent as ServiceIcon3 } from '../../images/slide-3.svg';
import { ReactComponent as ServiceIcon4 } from '../../images/slide-4.svg';
import { ReactComponent as ServiceIcon5 } from '../../images/slide-5.svg';

import { Service, type IServiceProps } from './components/Service';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import { useSelector } from 'react-redux';
import EdiText from 'react-editext';
import s from './Services.module.scss';

import { ISectionProps } from 'types/props';
import { getIconOrderCounter } from 'utils/index';
import classNames from 'classnames';

interface IServicesProps extends ISectionProps {}

const BACKGROUNDS: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
  ServiceIcon1,
  ServiceIcon2,
  ServiceIcon3,
  ServiceIcon4,
  ServiceIcon5,
};

export const Services: React.FC<IServicesProps> = (props) => {
  const { section, children } = props;
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const isEditMode = useSelector(isEditModeSelector);
  const [updateText] = useUpdateTextMutation();
  const getIconOrder = getIconOrderCounter(5);

  const serviceSectionContext = section?.child?.context;

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  const servicesSection: IServiceProps[] = section.children.map((elem) => ({
    itemTitle: elem.child.context.itemTitle,
    itemDescription: elem.child.context.itemDescription,
    contentImageUrl: elem.child.context.contentImageUrl,
    id: elem.id,
    ServiceIcon: BACKGROUNDS[`ServiceIcon${getIconOrder()}`],
  }));

  return (
    <section className={s.Services} id={section.id}>
      <div className='container'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={classNames(s.Services__description, 'description')}
          type={'text'}
          value={serviceSectionContext.servicesDescription}
          onSave={(value) => handleSave(value, 'servicesDescription')}
        />
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={classNames(s.Services__title, 'title')}
          type={'text'}
          value={serviceSectionContext.servicesTitle}
          onSave={(value) => handleSave(value, 'servicesTitle')}
        />
        <Swiper
          spaceBetween={30}
          pagination={{
            type: 'bullets',
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
          navigation
          modules={[Pagination, Navigation]}
        >
          {servicesSection.map((service) => (
            <SwiperSlide key={service.id} className={s.Services__slide}>
              <Service {...service} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {children}
    </section>
  );
};
