import { ISectionProps } from 'types/props';
import EdiText from 'react-editext';
import { useSelector } from 'react-redux';
import { isEditModeSelector } from 'slices/globalSlice';
import {
  FixedTemplateBlockName,
  selectHeaderLinks,
  useGetTemplateQuery,
  useUpdateTextMutation,
} from 'services/templateService';
import s from './Discuss.module.scss';
import classNames from 'classnames';
import { Button } from 'components/Button';

interface ICTAProps extends ISectionProps {}

export const Discuss: React.FC<ICTAProps> = (props) => {
  const { section, children, template } = props;

  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });

  const isEditMode = useSelector(isEditModeSelector);
  const [updateText] = useUpdateTextMutation();
  const CTASectionContext = section?.child?.context;
  const links = useSelector(selectHeaderLinks(domain));
  const contactsSection = links.find((link) => link.name === FixedTemplateBlockName.CONTACTS);

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <section className={s.discuss} id={section.id}>
      <div className='container'>
        <div className={s.discuss__wrap}>
          <EdiText
            editButtonProps={{ hidden: !isEditMode }}
            type={'text'}
            value={CTASectionContext.ctaDescription}
            onSave={(value) => handleSave(value, 'ctaDescription')}
            className={classNames(s.discuss__description, 'description')}
          />
          <EdiText
            editButtonProps={{ hidden: !isEditMode }}
            type={'text'}
            value={CTASectionContext.ctaTitle}
            onSave={(value) => handleSave(value, 'ctaTitle')}
            className={classNames(s.discuss__title, 'title')}
          />
          <Button
            className={classNames(s.discuss__btn, 'btn', `btn--${template}`)}
            containerClassName={s.discuss__btnContainer}
            id={section.id}
            btnValue={CTASectionContext.ctaCTA}
            btnLink={CTASectionContext.ctaLink}
            btnPropName={'ctaCTA'}
            btnLinkPropName={'ctaLink'}
            to={contactsSection?.id ?? ''}
          />
        </div>
      </div>
      {children}
    </section>
  );
};
