import { configureStore } from '@reduxjs/toolkit';
import { templateApi } from 'services/templateService';
import { globalSlice } from 'slices/globalSlice';

export const store = configureStore({
  reducer: {
    [globalSlice.name]: globalSlice.reducer,
    [templateApi.reducerPath]: templateApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(templateApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
