import { Testimonial, type ITestimonialProps } from './components/Testimonial';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { AVATARS } from 'constants/index';
import { isEditModeSelector } from 'slices/globalSlice';
import { useSelector } from 'react-redux';
import EdiText from 'react-editext';
import s from './Testimonials.module.scss';

import { ISectionProps } from 'types/props';

interface ITestimonialsProps extends ISectionProps {}

export const Testimonials: React.FC<ITestimonialsProps> = (props) => {
  const { section, children } = props;
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const isEditMode = useSelector(isEditModeSelector);
  const [updateText] = useUpdateTextMutation();

  const testimonialSectionContext = section?.child?.context;

  const testimonialsSection: ITestimonialProps[] = section.children.map((elem, i) => ({
    id: elem.id,
    reviewText: elem.child.context.reviewText,
    reviewerName: elem.child.context.reviewerName,
    userImage: elem.child.context.contentImageUrl || AVATARS[i],
  }));

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <section className={s.Testimonials} id={section.id}>
      <div className='container'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.Testimonials__title}
          type={'text'}
          value={
            testimonialSectionContext.testimonialsTitle || 'Hear It From Our Satisfied Clients'
          }
          onSave={(value) => handleSave(value, 'testimonialsTitle')}
        />
        <div className={s.Testimonials__wrap}>
          {testimonialsSection.map((testimonial) => (
            <Testimonial key={testimonial.id} {...testimonial} />
          ))}
        </div>
      </div>
      {children}
    </section>
  );
};
