import Student from 'assets/porfolio/techsters.png';

import s from './GetStarted.module.scss';
import { ISectionProps } from 'types/props';
import { useSelector } from 'react-redux';
import {
  FixedTemplateBlockName,
  selectHeaderLinks,
  useGetTemplateQuery,
  useUpdateTextMutation,
} from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import EdiText from 'react-editext';
import classNames from 'classnames';
import { Link } from 'react-scroll';
import { FIXED_HEADER_HEIGHT, SRCOLL_DELAY } from 'constants/index';
import { ImageUploader } from 'components/ImageUploader';

interface IGetStartedProps extends ISectionProps {}

export const GetStarted: React.FC<IGetStartedProps> = (props) => {
  const { section, children, template } = props;

  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');
  const portfolioSectionContext = section?.child?.context;
  const links = useSelector(selectHeaderLinks(domain));
  const contactsSection = links.find((link) => link.name === FixedTemplateBlockName.CONTACTS);

  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const handleSave = (context: Record<string, string>, id: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context,
      },
    });
  };

  return (
    <section className={s.GetStarted__container} id={section.id}>
      <div className='container'>
        <div className={s.GetStarted__content}>
          <div className={s.GetStarted__textWrapper}>
            <EdiText
              editButtonProps={{ hidden: !isEditMode }}
              type={'text'}
              className={classNames(s.GetStarted__title, 'title')}
              value={portfolioSectionContext.caseTitle}
              onSave={(value) => handleSave({ caseTitle: value }, section.id)}
            />
            <EdiText
              editButtonProps={{
                hidden: !isEditMode,
              }}
              type={'text'}
              className={classNames(s.GetStarted__text, 'description')}
              value={portfolioSectionContext.caseDescription}
              onSave={(value) => handleSave({ caseDescription: value }, section.id)}
            />
            <Link
              smooth
              spy
              to={isEditMode ? '' : contactsSection?.id ?? ''}
              offset={FIXED_HEADER_HEIGHT}
              delay={SRCOLL_DELAY}
              className={classNames('btn', `btn--${template}`)}
            >
              <EdiText
                editButtonProps={{ hidden: !isEditMode }}
                type={'text'}
                value={portfolioSectionContext.caseCTA || 'Discover More'}
                onSave={(value) => handleSave(value, 'aboutCTA')}
              />
            </Link>
          </div>
          <div className={s.GetStarted__imageWrapper}>
            <ImageUploader
              src={portfolioSectionContext.contentImageUrl || Student}
              alt=''
              nodeId={section.id}
            />
          </div>
        </div>
        {children}
      </div>
    </section>
  );
};
