import { ScrollRestoration, useLocation, useParams } from 'react-router-dom';
import { TRouteParams } from 'types/routes';
import { selectBlogById, selectSortedSections } from 'services/templateService/selectors';
import { useSelector } from 'react-redux';
import {
  FixedTemplateBlockName,
  useGetTemplateQuery,
  useUpdateTextMutation,
} from 'services/templateService';
import { Loading } from 'components/Loading';
import EdiText from 'react-editext';
import { isEditModeSelector } from 'slices/globalSlice';
import { ImageUploader } from 'components/ImageUploader';
import { Header } from 'components/sections/Header';

export const BlogPage: React.FC = () => {
  const { blogId, domain } = useParams<TRouteParams>();
  const { state } = useLocation();

  if (!domain || !blogId) return null;

  const { isLoading } = useGetTemplateQuery({ domain });

  const isEditMode = useSelector(isEditModeSelector);
  const blogPage = useSelector(selectBlogById(domain, blogId));
  const blogPageContext = blogPage?.child.context;
  const header = useSelector(selectSortedSections(domain)).find(
    (section) => section.child.name === FixedTemplateBlockName.HEADER
  );

  const [updateText] = useUpdateTextMutation();

  if (isLoading) return <Loading />;
  if (!header) return null;

  const handleSave = (context: Record<string, string>, id: string) => {
    updateText({
      domain,
      node: {
        id,
        context,
      },
    });
  };

  return (
    <>
      <Header section={header} template={header.child.type} isBackView />
      <main className='pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white'>
        <div className='flex justify-between px-4 mx-auto max-w-screen-xl '>
          <article className='mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue '>
            <header className='mb-4 lg:mb-6 not-format'>
              <address className='flex items-center mb-6 not-italic'>
                <div className='inline-flex items-center mr-3 text-sm text-gray-900 '>
                  <ImageUploader
                    src={blogPageContext?.blogAvatarPhoto || state?.itemAvatar}
                    alt={blogPageContext?.itemAuthor}
                    className='w-16 h-16 rounded-full m-auto'
                    nodeId={blogId}
                    contextImageParam={'blogAvatarPhoto'}
                  />
                  <div className={'ml-3'}>
                    <EdiText
                      editButtonProps={{ hidden: !isEditMode }}
                      type={'text'}
                      className='text-xl font-bold text-gray-900'
                      value={blogPageContext?.itemAuthor ?? ''}
                      onSave={(value) => handleSave({ itemAuthor: value }, blogId)}
                    />
                    {/* <p className='text-base font-light text-gray-500 '> */}
                    {/*  Graphic Designer, educator & CEO Flowbite */}
                    {/* </p> */}
                    <EdiText
                      editButtonProps={{ hidden: !isEditMode }}
                      type={'text'}
                      className='text-base font-light text-gray-500'
                      value={blogPageContext?.itemDate ?? ''}
                      onSave={(value) => handleSave({ itemDate: value }, blogId)}
                    />
                  </div>
                </div>
              </address>
              <EdiText
                editButtonProps={{ hidden: !isEditMode }}
                type={'text'}
                className='mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl'
                value={blogPageContext?.itemTitle ?? ''}
                onSave={(value) => handleSave({ itemTitle: value }, blogId)}
              />
            </header>
            <EdiText
              editButtonProps={{ hidden: !isEditMode }}
              type={'text'}
              className='lead mt-3 mb-3 text-gray-500'
              value={blogPageContext?.itemDescription ?? ''}
              onSave={(value) => handleSave({ itemDescription: value }, blogId)}
            />
            <EdiText
              editButtonProps={{ hidden: !isEditMode }}
              type={'text'}
              className='lead mt-3 mb-3 text-gray-500'
              value={blogPageContext?.itemText ?? ''}
              onSave={(value) => handleSave({ itemText: value }, blogId)}
            />
            <figure>
              <ImageUploader
                src={blogPageContext?.blogAvatarPhoto || state?.itemPhoto}
                alt={blogPageContext?.itemTitle}
                nodeId={blogId}
                contextImageParam={'blogPhotoPreview'}
              />
            </figure>
          </article>
        </div>
      </main>
      <ScrollRestoration />
    </>
  );
};
