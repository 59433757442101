import React, { useState } from 'react';
import classNames from 'classnames';

import s from './ColorDropdown.module.scss';

export interface DropdownOption {
  value: string;
  secondary: string;
  label: string;
}

interface DropdownProps {
  options: DropdownOption[];
  onSelect: (value: DropdownOption) => void;
}

export const ColorDropdown: React.FC<DropdownProps> = ({ options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectOption = (option: DropdownOption) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  return (
    <div className={s.dropdown}>
      <button type='button' className={s.dropdown__toggle} onClick={() => setIsOpen(!isOpen)}>
        {selectedOption ? (
          <div className={s.dropdown__label}>
            <span
              className={s.dropdown__color}
              style={{ background: `rgb(${selectedOption.value})` }}
            />
            {selectedOption.label}
          </div>
        ) : (
          'Select website theme'
        )}
      </button>
      {isOpen && (
        <ul className={s.dropdown__options}>
          {options.map((option) => (
            <li
              key={option.value}
              className={classNames(s.dropdown__option, { selected: option === selectedOption })}
            >
              <button
                className={s.dropdown__btn}
                type='button'
                onClick={() => handleSelectOption(option)}
                style={{ background: `rgb(${option.value})` }}
              >
                {option.label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
