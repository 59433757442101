import { FixedTemplateBlockName, TTemplateName } from 'services/templateService';

const LAST_TEMPLATE_NUMBER = 5;

export const getRegeneratedTemplateName = (
  templateName: TTemplateName,
  sectionName: FixedTemplateBlockName
): TTemplateName => {
  let templateNumber = Number(templateName.slice(-1));

  if (templateNumber >= LAST_TEMPLATE_NUMBER) {
    templateNumber = 0;
  }

  // Ниже условия, чтобы компоненты из разных шаблонов не повторялись
  // Удалить после добавления новых уникальных компонентов
  if (sectionName === FixedTemplateBlockName.SERVICES && templateNumber === 4) {
    templateNumber = 0;
  }

  if (sectionName === FixedTemplateBlockName.TESTIMONIALS && templateNumber === 1) {
    templateNumber = 2;
  }

  if (sectionName === FixedTemplateBlockName.TESTIMONIALS && templateNumber === 1) {
    templateNumber = 2;
  }

  if (sectionName === FixedTemplateBlockName.ADVANTAGES && templateNumber === 3) {
    templateNumber = 4;
  }

  if (sectionName === FixedTemplateBlockName.CONTACTS && templateNumber === 3) {
    templateNumber = 4;
  }

  if (
    sectionName === FixedTemplateBlockName.CTA &&
    (templateNumber === 5 || templateNumber === 1)
  ) {
    templateNumber = 2;
  }

  if (sectionName === FixedTemplateBlockName.CTA && templateNumber === 3) {
    templateNumber = 4;
  }

  if (
    sectionName === FixedTemplateBlockName.ACHIEVEMENTS &&
    (templateNumber === 1 || templateNumber === 2)
  ) {
    templateNumber = 3;
  }

  return `template${templateNumber + 1}` as TTemplateName;
};
