import React, { lazy, Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectRootSection,
  selectSortedSections,
  useGetTemplateQuery,
} from 'services/templateService';
import favicon from 'assets/favicon.png';
import { Loading } from 'components/Loading';
import { NotFound } from 'components/NotFound';
import { locationService } from 'services/locationService';

const V1Template = lazy(() => import('templates/V1Template'));
const V2Template = lazy(() => import('templates/V2Template'));
const V3Template = lazy(() => import('templates/V3Template'));
const V4Template = lazy(() => import('templates/V4Template'));
const V5Template = lazy(() => import('templates/V5Template'));

const TEMPLATES: Record<string, JSX.Element> = {
  template1: <V1Template />,
  template2: <V2Template />,
  template3: <V3Template />,
  template4: <V4Template />,
  template5: <V5Template />,
};

export const Homepage: React.FC = () => {
  const navigate = useNavigate();

  const { pathname } = new URL(document.location.href);
  const domain = pathname.replace('/', '');

  const rootSectionContext = useSelector(selectRootSection(domain))?.child?.context;
  const sections = useSelector(selectSortedSections(domain));

  const { data, isLoading, isError } = useGetTemplateQuery({ domain });

  useEffect(() => {
    locationService.configure(navigate);
    localStorage.setItem('websiteOwnerEmail', rootSectionContext?.websiteOwnerEmail ?? '');
  }, [rootSectionContext, navigate]);

  useEffect(() => {
    let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");

    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = favicon;
  }, []);

  useEffect(() => {
    if (rootSectionContext) {
      document.title = rootSectionContext.businessName;
    }
  }, [rootSectionContext]);

  if (isLoading) return <Loading />;

  if (isError || sections.length === 0) return <NotFound />;

  const template = data?.templateName;

  return (
    <Suspense fallback={<Loading />}>{template ? TEMPLATES[template] : 'Page not found'}</Suspense>
  );
};
