import EdiText from 'react-editext';
import { useSelector } from 'react-redux';
import { isEditModeSelector } from 'slices/globalSlice';
import { useUpdateTextMutation } from 'services/templateService';
import classNames from 'classnames';

interface IButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  id: string;
  link: string;
  linkPropName: string;
  containerClassName?: string;
}

export const Tooltip: React.FC<IButtonProps> = (props) => {
  const { children, id, containerClassName, link, linkPropName } = props;
  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');

  const [updateText] = useUpdateTextMutation();

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain,
      node: {
        id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <div className={classNames('group flex flex-col items-center max-w-sm z-10 relative')}>
      {children}
      {isEditMode && (
        <div
          className={classNames(
            'opacity-0 group-hover:opacity-100 inline-block transition-opacity px-3 py-2  font-medium text-gray-900 bg-white border border-gray-200 rounded shadow text-left absolute',
            containerClassName
          )}
        >
          <span>Edit the button's link:</span>
          <EdiText
            className='text-blue-600'
            editButtonProps={{ hidden: !isEditMode }}
            type={'text'}
            value={link}
            onSave={(value) => handleSave(value, linkPropName)}
          />
        </div>
      )}
    </div>
  );
};
