import { templateApi } from './api';

export { templateApi } from './api';
export type { TTemplateName, INode } from './types';
export type { IWebsiteTree } from './utils';

export { FixedTemplateBlockName } from './enums';
export {
  selectGetTemplateQuery,
  selectWebsite,
  selectWebsiteTree,
  selectRootSection,
  selectSortedSections,
  selectSectionIndexById,
  selectHeaderLinks,
} from './selectors';

export const {
  useGetTemplateQuery,
  useUpdateImageMutation,
  useUpdateTextMutation,
  useInsertBeforeMutation,
  useDeleteMutation,
  useChangeOrderMutation,
  useRegenerateMutation,
  useRegenerateComponentMutation,
} = templateApi;
