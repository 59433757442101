import { ReactComponent as ServiceIcon1 } from '../../images/icon-1.svg';
import { ReactComponent as ServiceIcon2 } from '../../images/icon-2.svg';
import { ReactComponent as ServiceIcon3 } from '../../images/icon-3.svg';
import { ReactComponent as ServiceIcon4 } from '../../images/icon-4.svg';
import { ReactComponent as ServiceIcon5 } from '../../images/icon-5.svg';
import { ReactComponent as ServiceIcon6 } from '../../images/icon-6.svg';

import { Service, type IServiceProps } from './components/Service';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { useSelector } from 'react-redux';
import { isEditModeSelector } from 'slices/globalSlice';
import EdiText from 'react-editext';
import { ISectionProps } from 'types/props';
import s from './Services.module.scss';
import classNames from 'classnames';

const BACKGROUNDS: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
  ServiceIcon1,
  ServiceIcon2,
  ServiceIcon3,
  ServiceIcon4,
  ServiceIcon5,
  ServiceIcon6,
};

interface IServicesProps extends ISectionProps {}

export const Services: React.FC<IServicesProps> = (props) => {
  const { section, children } = props;
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const isEditMode = useSelector(isEditModeSelector);
  const [updateText] = useUpdateTextMutation();

  const serviceSectionContext = section.child.context;

  const servicesSection: IServiceProps[] = section.children.map((elem, i) => ({
    id: elem.id,
    ServiceImage: BACKGROUNDS[`ServiceIcon${i + 1}`],
    itemTitle: elem.child.context.itemTitle,
    itemDescription: elem.child.context.itemDescription,
    contentImageUrl: elem.child.context.contentImageUrl,
  }));

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <section className={s.services} id={section.id}>
      <div className='container'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={classNames(s.services__title, 'title')}
          type={'text'}
          value={serviceSectionContext.servicesTitle || 'Comprehensive Offerings for Your Needs'}
          onSave={(value) => handleSave(value, 'servicesTitle')}
        />
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={classNames(s.services__description, 'description')}
          type={'text'}
          value={
            serviceSectionContext.servicesDescription || 'Experience Excellence in Every Service'
          }
          onSave={(value) => handleSave(value, 'servicesDescription')}
        />
        <div className={s.services__wrap}>
          {servicesSection.map((service) => (
            <Service key={service.id} {...service} />
          ))}
        </div>
      </div>
      {children}
    </section>
  );
};
