import EdiText from 'react-editext';

import { useSelector } from 'react-redux';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import s from './Team.module.scss';

export interface ITeamProps {
  id: string;
  teamImg: string;
  itemName: string;
  itemRole: string;
}

export const Team: React.FC<ITeamProps> = (props) => {
  const { teamImg, itemName, itemRole, id } = props;
  const domain = window.location.pathname.replace('/', '');
  const isEditMode = useSelector(isEditModeSelector);
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <div className={s.team}>
      <img src={teamImg} alt='' />
      <div className={s.team__hover}>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          className={s.team__name}
          type={'text'}
          value={itemName}
          onSave={(value) => handleSave(value, 'itemName')}
        />
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          type={'text'}
          value={itemRole}
          onSave={(value) => handleSave(value, 'itemRole')}
        />
      </div>
    </div>
  );
};
