import { ImageUploader } from 'components/ImageUploader';
import EdiText from 'react-editext';
import { useSelector } from 'react-redux';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import { ReactComponent as RatingIcon } from '../../../../images/rating.svg';

import s from './Testimonial.module.scss';

export interface ITestimonialProps {
  id: string;
  reviewText: string;
  userImage: string;
  reviewerName: string;
  companyName: string;
}

export const Testimonial: React.FC<ITestimonialProps> = (props) => {
  const { reviewText, userImage, reviewerName, id } = props;
  const isEditMode = useSelector(isEditModeSelector);

  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <div className={s.Testimonial}>
      <RatingIcon className={s.Testimonial__rating} />
      <EdiText
        editButtonProps={{ hidden: !isEditMode }}
        className={s.Testimonial__text}
        type={'text'}
        value={reviewText}
        onSave={(value) => handleSave(value, 'reviewText')}
      />
      <ImageUploader src={userImage} alt='User' className={s.Testimonial__photo} nodeId={id} />
      <EdiText
        editButtonProps={{ hidden: !isEditMode }}
        className={s.Testimonial__name}
        type={'text'}
        value={reviewerName}
        onSave={(value) => handleSave(value, 'reviewerName')}
      />
    </div>
  );
};
