export type Theme = 'red' | 'blue';

export enum EMode {
  start = 'start',
  view = 'view',
  pro = 'pro',
  edit = 'edit',
  trial = 'trial',
}

export interface GlobalState {
  mode: EMode | null;
  theme: Theme | null;
}
