import animate from '../../images/animate.svg';
import { ReactComponent as ContactsIcon } from '../../images/contacts1.svg';
import { ReactComponent as PhoneIcon } from '../../images/phone.svg';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import { useSelector } from 'react-redux';
import EdiText from 'react-editext';
import { ISectionProps } from 'types/props';
import s from './Contacts.module.scss';

interface IContactsProps extends ISectionProps {}

export const Contacts: React.FC<IContactsProps> = (props) => {
  const { section, children } = props;

  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const contactsSectionContext = section?.child?.context;

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <section className={s.contacts} id={section.id}>
      <div className='container'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          type={'text'}
          value={contactsSectionContext.contactsDescription || 'Come on baby light my fire!'}
          onSave={(value) => handleSave(value, 'contactsDescription')}
          className={s.contacts__title}
        />
        <div className={s.contacts__animate}>
          <img src={animate} alt='' />
          <PhoneIcon />
        </div>
        <div className={s.contacts__text}>
          <EdiText
            editButtonProps={{ hidden: !isEditMode }}
            type={'text'}
            value={contactsSectionContext.contacts}
            onSave={(value) => handleSave(value, 'contacts')}
          />
          <ContactsIcon />
        </div>
      </div>
      {children}
    </section>
  );
};
