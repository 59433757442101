import EdiText from 'react-editext';
import 'swiper/css';
import 'swiper/css/navigation';

import { isEditModeSelector } from 'slices/globalSlice';
import { useSelector } from 'react-redux';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { ImageUploader } from 'components/ImageUploader';
import s from './Service.module.scss';

export interface IServiceProps {
  id: string;
  itemTitle: string;
  contentImageUrl: string;
  itemDescription: string;
  ServiceIcon: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const Service: React.FC<IServiceProps> = (props) => {
  const { itemTitle, itemDescription, id, ServiceIcon, contentImageUrl } = props;
  const isEditMode = useSelector(isEditModeSelector);

  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });
  const [updateText] = useUpdateTextMutation();

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <div className={s.service}>
      {contentImageUrl || isEditMode ? (
        <div className={s['service__heading-image']}>
          <ImageUploader src={contentImageUrl} alt='' nodeId={id} />
        </div>
      ) : (
        <div className={s['service__heading-image']}>{ServiceIcon && <ServiceIcon />}</div>
      )}
      <EdiText
        editButtonProps={{ hidden: !isEditMode }}
        className={s.service__ttl}
        type={'text'}
        value={itemTitle}
        onSave={(value) => handleSave(value, 'itemTitle')}
      />
      <div className={s.service__descr}>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          type={'text'}
          value={itemDescription}
          onSave={(value) => handleSave(value, 'itemDescription')}
        />
      </div>
    </div>
  );
};
