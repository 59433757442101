import { EMode } from './types';

export const isMode = (param: unknown): param is EMode => {
  if (typeof param !== 'string') {
    return false;
  }

  const modes = Object.values(EMode) as string[];

  return modes.includes(param);
};
