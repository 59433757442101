import classnames from 'classnames';
import './mobile-menu.scss';
import { Link } from 'react-scroll';
import { FIXED_MOBILE_HEADER_HEIGHT, HEADER_LINK_NAMES, SRCOLL_DELAY } from 'constants/index';
import { useSelector } from 'react-redux';
import { selectHeaderLinks } from 'services/templateService';

interface IMobileMenuProps {
  isOpened: boolean;
  onToggle: () => void;
}

export const MobileMenu: React.FC<IMobileMenuProps> = (props) => {
  const { isOpened } = props;
  const domain = window.location.pathname.replace('/', '');

  const links = useSelector(selectHeaderLinks(domain));

  return (
    <div className={classnames('mobile-menu', { active: isOpened })}>
      <nav className='mobile-menu__menu'>
        <ul>
          {links.map((link) => (
            <li key={link.id}>
              <Link
                activeClass='active'
                smooth
                spy
                to={link.id}
                offset={FIXED_MOBILE_HEADER_HEIGHT}
                delay={SRCOLL_DELAY}
              >
                {HEADER_LINK_NAMES[link.name]}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};
