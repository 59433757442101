import { Header } from 'components/sections/Header';
import { Footer } from 'components/sections/Footer';
import { Form } from 'components/sections/Form';
import { Pricing } from 'components/sections/Pricing';
import { Faqs } from 'components/sections/Faqs';

import { Head as Head1 } from 'templates/V1Template/sections/Head';
import { Head as Head2 } from 'templates/V2Template/sections/Head';
import { Head as Head3 } from 'templates/V3Template/sections/Head';
import { Head as Head4 } from 'templates/V4Template/sections/Head';
import { Head as Head5 } from 'templates/V5Template/sections/Head';

import { Services as Services1 } from 'templates/V1Template/sections/Services';
import { Services as Services2 } from 'templates/V2Template/sections/Services';
import { Services as Services4 } from 'templates/V4Template/sections/Services';
import { Services as Services5 } from 'templates/V5Template/sections/Services';

import { Testimonials as Testimonials1 } from 'templates/V1Template/sections/Testimonials';
import { Testimonials as Testimonials3 } from 'templates/V3Template/sections/Testimonials';
import { Testimonials as Testimonials4 } from 'templates/V4Template/sections/Testimonials';
import { Testimonials as Testimonials5 } from 'templates/V5Template/sections/Testimonials';

import { Advantages as Advantages1 } from 'templates/V1Template/sections/Advantages';
import { Advantages as Advantages2 } from 'templates/V2Template/sections/Advantages';
import { Advantages as Advantages3 } from 'templates/V3Template/sections/Advantages';
import { Advantages as Advantages5 } from 'templates/V5Template/sections/Advantages';

import { Contacts as Contacts1 } from 'templates/V1Template/sections/Contacts';
import { Contacts as Contacts2 } from 'templates/V2Template/sections/Contacts';
import { Contacts as Contacts3 } from 'templates/V3Template/sections/Contacts';
import { Contacts as Contacts5 } from 'templates/V5Template/sections/Contacts';

import { Discuss as CTA1 } from 'templates/V1Template/sections/Discuss';
import { Connect as CTA3 } from 'templates/V3Template/sections/Connect';

import { Form as Form5 } from 'templates/V5Template/sections/Form';

import { Achievements as Achievements4 } from 'templates/V4Template/sections/Achievements';
import { Achievements as Achievements5 } from 'templates/V5Template/sections/Achievements';

import { Animation as Animation5 } from 'templates/V5Template/sections/Animation';
import { CrossAnimation as CrossAnimation5 } from 'templates/V5Template/sections/CrossAnimation';

import { Teams as Teams4 } from 'templates/V4Template/sections/Teams';
import { Partners } from 'templates/V4Template/sections/Partners';
import { GetStarted } from 'components/sections/Portfolio/GetStarted';
import { Blog } from 'components/sections/Blog';
import { Gallery } from 'components/sections/Gallery';
import { AndroidMobileCTA, IOSMobileCTA } from 'components/sections/MobileCTA';

import type { ISectionProps } from 'types/props';
import type { TTemplateName } from 'services/templateService';

export const HEADER: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: Header,
  template2: Header,
  template3: Header,
  template4: Header,
  template5: Header,
};

export const FOOTER: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: Footer,
  template2: Footer,
  template3: Footer,
  template4: Footer,
  template5: Footer,
};

export const FORM: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: Form,
  template2: Form,
  template3: Form,
  template4: Form,
  template5: Form5,
};

export const ABOUT_US: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: Head1,
  template2: Head2,
  template3: Head3,
  template4: Head4,
  template5: Head5,
};

export const SERVICES: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: Services1,
  template2: Services2,
  template3: Services5,
  template4: Services4,
  template5: Services5,
};

export const TESTIMONIALS: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: Testimonials1,
  template2: Testimonials1,
  template3: Testimonials3,
  template4: Testimonials4,
  template5: Testimonials5,
};

export const ADVANTAGES: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: Advantages1,
  template2: Advantages2,
  template3: Advantages3,
  template4: Advantages3,
  template5: Advantages5,
};

export const CONTACTS: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: Contacts1,
  template2: Contacts2,
  template3: Contacts3,
  template4: Contacts3,
  template5: Contacts5,
};

export const CTA: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: CTA1,
  template2: CTA1,
  template3: CTA3,
  template4: CTA3,
  template5: CTA1,
};

export const ACHIEVEMENTS: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: Achievements4,
  template2: Achievements4,
  template3: Achievements4,
  template4: Achievements4,
  template5: Achievements5,
};

export const TEAM: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: Teams4,
  template2: Teams4,
  template3: Teams4,
  template4: Teams4,
  template5: Teams4,
};

export const ANIMATION: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: Animation5,
  template2: Animation5,
  template3: Animation5,
  template4: Animation5,
  template5: Animation5,
};

export const CROSS_ANIMATION: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: CrossAnimation5,
  template2: CrossAnimation5,
  template3: CrossAnimation5,
  template4: CrossAnimation5,
  template5: CrossAnimation5,
};

export const PRICING: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: Pricing,
  template2: Pricing,
  template3: Pricing,
  template4: Pricing,
  template5: Pricing,
};

export const FAQS: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: Faqs,
  template2: Faqs,
  template3: Faqs,
  template4: Faqs,
  template5: Faqs,
};

export const PARTNERS: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: Partners,
  template2: Partners,
  template3: Partners,
  template4: Partners,
  template5: Partners,
};

export const PORTFOLIO: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: GetStarted,
  template2: GetStarted,
  template3: GetStarted,
  template4: GetStarted,
  template5: GetStarted,
};

export const BLOG: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: Blog,
  template2: Blog,
  template3: Blog,
  template4: Blog,
  template5: Blog,
};

export const GALLERY: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: Gallery,
  template2: Gallery,
  template3: Gallery,
  template4: Gallery,
  template5: Gallery,
};

export const MOBILE_IOS: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: IOSMobileCTA,
  template2: IOSMobileCTA,
  template3: IOSMobileCTA,
  template4: IOSMobileCTA,
  template5: IOSMobileCTA,
};

export const MOBILE_ANDROID: Record<TTemplateName, React.FC<ISectionProps>> = {
  template1: AndroidMobileCTA,
  template2: AndroidMobileCTA,
  template3: AndroidMobileCTA,
  template4: AndroidMobileCTA,
  template5: AndroidMobileCTA,
};
