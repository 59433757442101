import EdiText from 'react-editext';
import { useSelector } from 'react-redux';
import { useGetTemplateQuery, useUpdateTextMutation } from 'services/templateService';
import { isEditModeSelector } from 'slices/globalSlice';
import { ISectionProps } from 'types/props';
import { Achievement } from './components/Achievement';
import s from './Achievements.module.scss';

interface IAchievementsProps extends ISectionProps {}

export const Achievements: React.FC<IAchievementsProps> = (props) => {
  const { children, section } = props;
  const domain = window.location.pathname.replace('/', '');
  const { data } = useGetTemplateQuery({ domain });

  const isEditMode = useSelector(isEditModeSelector);
  const [updateText] = useUpdateTextMutation();
  const achievementsSectionContext = section?.child?.context;

  const achievements = section.children.map((achievement) => ({
    id: achievement.id,
    itemMetric: achievement.child.context.itemMetric,
    itemValue: achievement.child.context.itemValue,
  }));

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain: data?.domain || 'domain',
      node: {
        id: section.id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <section className={s.achievements}>
      <div className='container'>
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          type={'text'}
          value={achievementsSectionContext.achievementTitle}
          onSave={(value) => handleSave(value, 'achievementTitle')}
          className='title centerEditext'
        />
        <EdiText
          editButtonProps={{ hidden: !isEditMode }}
          type={'text'}
          value={achievementsSectionContext.achievementDescription}
          onSave={(value) => handleSave(value, 'achievementDescription')}
          className='description centerEditext'
        />
        <div className={s.achievements__overflow}>
          <div className={s.achievements__wrap}>
            {achievements.map((achievement) => (
              <Achievement key={achievement.id} {...achievement} />
            ))}
          </div>
        </div>
      </div>
      {children}
    </section>
  );
};
