import { SectionOption } from 'components/AddSection';
import { FixedTemplateBlockName } from 'services/templateService';

const SECTION_NAMES: { [key in FixedTemplateBlockName]?: string } = {
  [FixedTemplateBlockName.ABOUT]: 'About us',
  [FixedTemplateBlockName.ADVANTAGES]: 'Advantages',
  [FixedTemplateBlockName.TESTIMONIALS]: 'Reviews',
  [FixedTemplateBlockName.SERVICES]: 'Services',
  [FixedTemplateBlockName.CTA]: 'CTA section',
  [FixedTemplateBlockName.FORM]: 'Form',
  [FixedTemplateBlockName.CONTACTS]: 'Contacts',
  [FixedTemplateBlockName.FOOTER]: 'Footer',
  [FixedTemplateBlockName.ACHIEVEMENTS]: 'Achievements',
  [FixedTemplateBlockName.ANIMATION]: 'Animation',
  [FixedTemplateBlockName.CROSSANIMATION]: 'Cross Animation',
  [FixedTemplateBlockName.CUSTOMERS]: 'Customers',
  [FixedTemplateBlockName.FAQS]: 'FAQ',
  [FixedTemplateBlockName.PRICING]: 'Pricing',
  [FixedTemplateBlockName.PORTFOLIO]: 'Portfolio Case',
  [FixedTemplateBlockName.BLOG]: 'Blog',
  [FixedTemplateBlockName.GALLERY]: 'Gallery',
  [FixedTemplateBlockName.MOBILE_IOS]: 'Mobile IOS',
  [FixedTemplateBlockName.MOBILE_ANDROID]: 'Mobile Android',
};

export const SECTION_OPTIONS: SectionOption[] = Object.entries(SECTION_NAMES)
  .map((section) => ({
    value: section[0],
    label: section[1],
  }))
  .sort((a, b) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });

export const SINGLE_COMPONENTS = [
  FixedTemplateBlockName.ANIMATION,
  FixedTemplateBlockName.CROSSANIMATION,
  FixedTemplateBlockName.PRICING,
  FixedTemplateBlockName.FAQS,
  FixedTemplateBlockName.TEAM,
  FixedTemplateBlockName.FOOTER,
  FixedTemplateBlockName.CUSTOMERS,
  FixedTemplateBlockName.GALLERY,
  FixedTemplateBlockName.BLOG,
  FixedTemplateBlockName.PORTFOLIO,
  FixedTemplateBlockName.MOBILE_ANDROID,
  FixedTemplateBlockName.MOBILE_IOS,
];
