import EdiText from 'react-editext';
import { Link } from 'react-scroll';
import { useSelector } from 'react-redux';
import { isEditModeSelector } from 'slices/globalSlice';
import { FIXED_HEADER_HEIGHT, SRCOLL_DELAY } from 'constants/index';
import { useUpdateTextMutation } from 'services/templateService';
import classNames from 'classnames';

import s from './Button.module.scss';

interface IButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  btnValue: string;
  btnLink: string;
  id: string;
  btnPropName: string;
  btnLinkPropName: string;
  to: string;
  containerClassName?: string;
}

export const Button: React.FC<IButtonProps> = (props) => {
  const {
    children,
    btnValue,
    btnPropName,
    btnLinkPropName,
    className,
    id,
    to,
    btnLink,
    containerClassName,
  } = props;
  const isEditMode = useSelector(isEditModeSelector);
  const domain = window.location.pathname.replace('/', '');

  const [updateText] = useUpdateTextMutation();

  const handleSave = (val: string, propName: string) => {
    updateText({
      domain,
      node: {
        id,
        context: {
          [propName]: val,
        },
      },
    });
  };

  return (
    <div className={classNames('group flex flex-col', s.Button__group, containerClassName)}>
      {btnLink ? (
        <a
          {...(!isEditMode && { href: btnLink })}
          className={className}
          rel='noreferrer'
          target={'_blank'}
        >
          <EdiText
            editButtonProps={{ hidden: !isEditMode }}
            type={'text'}
            value={btnValue}
            onSave={(value: string) => handleSave(value, btnPropName)}
          />
          {children}
        </a>
      ) : (
        <Link
          smooth
          spy
          to={isEditMode ? '' : to}
          offset={FIXED_HEADER_HEIGHT}
          delay={SRCOLL_DELAY}
          className={className}
        >
          <EdiText
            editButtonProps={{ hidden: !isEditMode }}
            type={'text'}
            value={btnValue}
            onSave={(value: string) => handleSave(value, btnPropName)}
          />
          {children}
        </Link>
      )}
      {isEditMode && (
        <div
          className={
            'opacity-0 group-hover:opacity-100 transition-opacity inline-block px-3 py-2  font-medium text-gray-900 bg-white border border-gray-200 rounded shadow text-left'
          }
        >
          <span>Edit the button's link:</span>
          <EdiText
            className='text-blue-600'
            editButtonProps={{ hidden: !isEditMode }}
            type={'text'}
            value={btnLink}
            onSave={(value) => handleSave(value, btnLinkPropName)}
          />
        </div>
      )}
    </div>
  );
};
